import React from 'react';
import { Link } from 'react-router-dom';
import { leftMenuItems } from '../../components/left-menu/left-menu';
import styles from './home.module.css';

const Home: React.FC = () => {
  return (
    <div className={styles.wrap}>
      <div className={styles.menu}>
        {
          leftMenuItems.map(({path, Icon, label}) => (
            <Link key={path} to={path} className={styles.item}>
              <Icon className={styles.icon} fontSize="inherit" />
              <p>{label}</p>
            </Link>
          ))
        }
      </div>
    </div>
  );
};

export default Home;