import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import {default as SwipeableDrawer, SwipeableDrawerProps} from '@mui/material/SwipeableDrawer';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Arrow from '../arrow/arrow';
import styles from './menu.module.css';
import PersonIcon from '@mui/icons-material/Person';
import { getAuth } from 'firebase/auth';

const Menu: React.FC<SwipeableDrawerProps> = props => {
  const auth = getAuth();
  const navigate = useNavigate();

  const logout = async () => {
    await auth.signOut();
    navigate('/auth/login');
  };

  return (
    <SwipeableDrawer anchor="right" {...props} className={styles.menuDrawer}>
      <Box className={styles.container} role="presentation">
        <div className={styles.account}>
          <Avatar className={styles.avatar}>
            <PersonIcon color="inherit" />
          </Avatar>
          <div>
            <p>{auth.currentUser?.displayName}</p>
            <p className={styles.email}>{auth.currentUser?.email}</p>
          </div>
        </div>
        <List>
          <ListItem>
            <Arrow color="primary" fontSize="inherit" />
            <Link to="/change-password" onClick={e => props.onClose(e)}>Change password</Link>
          </ListItem>
          <ListItem>
            <Arrow color="primary" fontSize="inherit" />
            <button className={styles.linkButton} onClick={logout}>Log out</button>
          </ListItem>
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default Menu;