import React from 'react';
import styles from './price-who.module.css';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import Filters from '../../components/filters/filters';
import observableFiltersStore from '../../stores/filters';
import getPriceUserData, { PriceUserDataModel } from '../../client/get-price-user-data';
import PriceSegments from '../../components/price-segments-for-price-who/price-segments';
import Geography from '../../components/product-geography/geography';
import Age from '../../components/product-age/age';
import Consumption from '../../components/product-consumption/consumption';
import FamilyType from '../../components/product-family-type/family-type';
import FloatingToast from '../../components/toast-for-switching-price/floating-toast';

const PriceWho: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [interval, setInterval] = React.useState<string>('');
  const [intervalName, setIntervalName] = React.useState<string>('');
  const [data, setData] = React.useState<PriceUserDataModel>();
  
  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const result = await getPriceUserData(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.intervalStart,
        observableFiltersStore.intervalLength,
        intervalName,
      );

      if (result.success) {
        setData(result.data);
      }
      
      setIsLoading(false);
    });

    return () => dispose();
  }, [intervalName]);
  
  return (
    <div>
      <Filters />
      <div className={styles.wrapper}>
        <div className={styles.box1}>
          <PriceSegments interval={interval} intervalName={intervalName} setInterval={setInterval} setIntervalName={setIntervalName} />
        </div>
        <div className={styles.box2}>
          <Geography isLoading={isLoading} name={'Interval'} value={interval} data={data && data.geography ? data.geography : []} />
        </div>
        <div className={styles.box3}>
          <Age isLoading={isLoading} name={'Interval'} value={interval} data={data && data.age ? data.age : []} />
        </div>
        <div className={styles.box4}>
          <Consumption isLoading={isLoading} name={'Interval'} value={interval} data={data && data.consumption ? data.consumption : []} />
        </div>
        <div className={styles.box5}>
          <FamilyType isLoading={isLoading} name={'Interval'} value={interval} data={data && data.familyType ? data.familyType : []} />
        </div>
      </div>
      <FloatingToast bold={'who'} />
    </div>
  );
};

export default observer(PriceWho);
