import React from 'react';
import styles from './transition-profile.module.css';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import observableFiltersStore from '../../stores/filters';
import dateFormat from 'date-fns/format';
import getBrandLossAndGainForWhoPage from '../../client/get-brand-loss-and-gain-for-who-page';
import { ResponsiveBar } from '@nivo/bar';

interface TransitionProfileProps {
  functionality: string,
  setFunctionality: (functionality: string) => void,
}

const TransitionProfile: React.FC<TransitionProfileProps> = (props: TransitionProfileProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any[]>();

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const resultTransitionProfile = await getBrandLossAndGainForWhoPage(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
      );

      if (resultTransitionProfile.success) {
        setData(resultTransitionProfile.data);
      }

      setIsLoading(false);
    });

    return () => dispose();
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2>TRANSITION-PROFILE</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
        {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
        {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
        <p>Source: Heco et al</p>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#0096FF'}}></div>
            <div className={styles.item}>Gain from market in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#21B8FE'}}></div>
            <div className={styles.item}>Increased spending in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#A3E2FF'}}></div>
            <div className={styles.item}>New buyers in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#FFC62F'}}></div>
            <div className={styles.item}>Loss to market in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#FF9D2C'}}></div>
            <div className={styles.item}>Decreased spending in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#FE872B'}}></div>
            <div className={styles.item}>Lost buyers in %</div>
          </div>
        </div>
      </div>
      <div style={{height: '30rem'}}>
        {isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
        {!isLoading && data && (
          <ResponsiveBar
            data={data}
            indexBy="Name"
            keys={[
              'Gain from market in %', 
              'Increased spending in %', 
              'New buyers in %', 
              'Lost buyers in %', 
              'Decreased spending in %', 
              'Loss to market in %', 
            ].reverse()}
            groupMode="stacked"
            layout="vertical"
            padding={0.4}
            margin={{ top: 25, right: 0, bottom: 25, left: 0 }}
            colors={['#0096FF', '#21B8FE', '#A3E2FF', '#FE872B', '#FF9D2C', '#FFC62F'].reverse()}
            indexScale={{ type: 'band', round: true }}
            enableGridX={false}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            labelSkipWidth={12}
            labelSkipHeight={0}
            labelTextColor="black"
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: data.length > 20 ? 7 : data.length > 15 ? 9 : 11,
                    fontWeight: 'bold',
                  },
                },
              },
            }}
            ariaLabel="Transition Profile Bar Chart"
            onClick={(data) => {
              const functionalityClicked = data.id.toString();
              if (functionalityClicked === 'Increased spending in %') {
                props.setFunctionality('Increased spending');
              } else if (functionalityClicked === 'Gain from market in %') {
                props.setFunctionality('Gain from market');
              } else if (functionalityClicked === 'Loss to market in %') {
                props.setFunctionality('Loss to market');
              } else if (functionalityClicked === 'Decreased spending in %') {
                props.setFunctionality('Decreased spending');
              } else if (functionalityClicked === 'Lost buyers in %') {
                props.setFunctionality('Lost buyers');
              } else if (functionalityClicked === 'New buyers in %') {
                props.setFunctionality('New buyers');
              }
            }}
          />
        )}
      </div>
      {props.functionality &&
        <div className={styles.clearChoiceButton}>
          <div>Functionality: {props.functionality}</div>
          <button onClick={() => props.setFunctionality('')}>
            Clear choice
          </button>
        </div>
      }
    </div>
  );
};

export default observer(TransitionProfile);
