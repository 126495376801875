import { getAuth } from 'firebase/auth';
import {Result} from './models';
import dateFormat from 'date-fns/format';
import {Brand, Category, Merchant} from '../stores/filters';

interface FilterValues {
  brands: Brand[];
  categories: Category[];
  merchants: Merchant[];
  defaultCategory?: Category;
}

const getFilterValues = async (dateFrom: Date, dateTo: Date, categoryId?: string, brandIds?: string[], merchantIds?: string[]): Promise<Result<FilterValues>> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();

  if (!token) {
    throw new Error('Unauthorized');
  }

  const response = await fetch(`${process.env.REACT_APP_API}/v1/get-filter-values?date_from=${dateFormat(dateFrom, 'yyyy-MM-dd')}&date_to=${dateFormat(dateTo, 'yyyy-MM-dd')}&category_id=${categoryId || ''}&brand_ids=${brandIds?.reduce((acc: string[], curr) => acc = [...acc, encodeURIComponent(curr)], [])?.join(',') || ''}&merchant_ids=${merchantIds?.reduce((acc: string[], curr) => acc = [...acc, encodeURIComponent(curr)], [])?.join(',') || ''}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
  return await response.json();
};

export default getFilterValues;