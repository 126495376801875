import React from 'react';
import styles from './product-RBR.module.css';
import dateFormat from 'date-fns/format';
import { ResponsiveLine } from '@nivo/line';
import observableFiltersStore from '../../stores/filters';

const ProductRBR: React.FC<any> = (props: any) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2>RBR (REPEAT BUYING RATE)</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
        {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
        {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
        <p>Source: Heco et al</p>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#24B8FD'}}></div>
            <div className={styles.item}>Brands</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: 'red'}}></div>
            <div className={styles.item}>Category</div>
          </div>
        </div>
      </div>
      <div className={styles.graph}>
        {props.isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
        {!props.isLoading && props.data &&
          <ResponsiveLine
            data={props.data}
            margin={{ top: 50, right: 25, bottom: 50, left: 50 }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 'auto',
              stacked: false,
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Months',
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: props.data[0].data.length > 20 ? 8 : props.data[0].data.length > 15 ? 10 : 12,
                    fontWeight: 'bold',
                  },
                },
              },
            }}
            colors={['red', '#24B8FD']}
            pointSize={5}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
          />
        }
      </div>
    </div>
  );
};

export default ProductRBR;
