import React from 'react';
import styles from './market-value-drivers-pen.module.css';
import observableFiltersStore from '../../stores/filters';
import dateFormat from 'date-fns/format';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import getMarketValueDriversOne, { ValueDriversModelResultOne } from '../../client/get-market-value-drivers-one';
import BarChartItem from '../market-bar-chart-item/market-bar-chart-item';

const ValueDriversPen: React.FC = () => {
  const [data, setData] = React.useState<ValueDriversModelResultOne>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);
      const result = await getMarketValueDriversOne(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
      );
      if (result.success) {
        setData(result.data);
        setIsLoading(false);
      }
    });

    return () => dispose();
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2>TOP 10 MARKETS, VALUE DRIVERS</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        <p>Source: Heco et al</p>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#0096FF'}}></div>
            <div className={styles.item}>Current period</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#B2BEB5'}}></div>
            <div className={styles.item}>Last period</div>
          </div>
        </div>
      </div>
      <div className={styles.containerPen}>
        {isLoading || !data ?
          isLoading && <h3 className={styles.isLoading}>Loading...</h3> :
          <>
            <BarChartItem data={data?.penetration} legend={'Penetration'}/>
            <BarChartItem data={data?.frequency} legend={'Frequency'}/>
            <BarChartItem data={data?.basket} legend={'Basket size'}/>
          </>
        }
      </div>
    </div>
  );
};

export default observer(ValueDriversPen);