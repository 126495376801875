import React, { useEffect, useState } from 'react';
import { autorun } from 'mobx';
import styles from './place-sales-per-shopper.module.css';
import dateFormat from 'date-fns/format';
import { ResponsiveLine } from '@nivo/line';
import observableFiltersStore from '../../stores/filters';
import getPlaceSalesPerShopper from '../../client/get-place-sales-per-shopper';

const PlaceSalesPerShopper: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const result = await getPlaceSalesPerShopper(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
      );

      if (result.success) {
        setData(result.data);

        const length = result.data.length;
        const randomColors: string[] = [];
        for (let i = 0; i < length; i++) {
          randomColors.push(randColor());
        }
        setColors(randomColors);
      }

      setIsLoading(false);
    });

    return () => dispose();
  }, []);

  const randColor = () : string => {
    return '#' + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0').toUpperCase();
  };

  return (
    <div className={styles.wrap}>
      <div>
        <h2>SALES PER SHOPPER</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
        {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
        {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
        <p>Source: Heco et al</p>
      </div>
      <div className={styles.graph}>
        {isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
        {!isLoading && data && data.length > 0 &&
          <ResponsiveLine
            data={data}
            colors={['red', ...colors]}
            margin={{ top: 50, right: 25, bottom: 50, left: 50 }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 'auto',
              stacked: false,
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Month',
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: data[0].data.length > 20 ? 8 : data[0].data.length > 15 ? 10 : 12,
                    fontWeight: 'bold',
                  },
                },
              },
            }}
            pointSize={5}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: 'top-left',
                direction: 'row',
                justify: false,
                translateX: -25,
                translateY: -50,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 125,
                itemHeight: 40,
                itemOpacity: 0.75,
                symbolSize: 14,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        }
      </div>
    </div>
  );
};

export default PlaceSalesPerShopper;
