import React from 'react';
import styles from './brands-max-prices.module.css';
import { ResponsiveBar } from '@nivo/bar';
import dateFormat from 'date-fns/format';
import observableFiltersStore from '../../stores/filters';

const BrandsMaxPrices: React.FC<any> = (props: any) => {
  return (
    <>
      <div className={styles.wrap}>
        <div>
          <h2>MAX PRICE</h2>
          <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
          <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
          {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
          <p>Source: Heco et al</p>
        </div>
        <div className={styles.graph}>
          {props.isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
          {!props.isLoading && props.dataBrandsMaxPrices && props.brandNames &&
            <ResponsiveBar
              data={props.dataBrandsMaxPrices}
              keys={props.brandNames}
              indexBy="interval"
              margin={{ top: 30, right: 130, bottom: 30, left: 0 }}
              padding={0.4}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={props.colors}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 10,
                tickPadding: 1,
                tickRotation: 0,
              }}
              axisLeft={null}
              labelSkipWidth={12}
              labelSkipHeight={6}
              labelTextColor="black"
              theme={{
                axis: {
                  ticks: {
                    text: {
                      fontSize: props.dataBrandsMaxPrices.length > 20 ? 7 : props.dataBrandsMaxPrices.length > 15 ? 9 : 11,
                      fontWeight: 'bold',
                    },
                  },
                },
              }}
              enableGridY={false}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'top-right',
                  direction: 'column',
                  justify: false,
                  translateX: 110,
                  translateY: -15,
                  itemsSpacing: 3,
                  itemWidth: 116,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 14,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Brands Max Prices Bar Chart"
              barAriaLabel={function(e) {
                return e.id+': '+e.formattedValue+' in merchant: '+e.indexValue;
              }}
            />
          }
        </div>
      </div>
    </>
  );
};

export default BrandsMaxPrices;
