import { getAuth } from 'firebase/auth';
import { Result } from './models';
import dateFormat from 'date-fns/format';

export interface PromotionWeeksFrequencyResultObject { 
  week_number: string;
  frequency: number;
}

const getPromotionWeeksFrequency = async (dateFrom: Date, dateTo: Date, categoryId?: string, merchantIds?: string[], brandIds?: string[]): Promise<Result<PromotionWeeksFrequencyResultObject[]>> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();

  if (!token) {
    throw new Error('Unauthorized');
  }
  
  const response = await fetch(`${process.env.REACT_APP_API}/v1/get-promotion-weeks-frequency?date_from=${dateFormat(dateFrom, 'yyyy-MM-dd')}&date_to=${dateFormat(dateTo, 'yyyy-MM-dd')}&category_id=${categoryId || ''}&merchant_ids=${merchantIds?.reduce((acc: string[], curr) => acc = [...acc, encodeURIComponent(curr)], [])?.join(',') || ''}&brand_ids=${brandIds?.reduce((acc: string[], curr) => acc = [...acc, encodeURIComponent(curr)], [])?.join(',') || ''}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
  return await response.json();
};

export default getPromotionWeeksFrequency;
