import React from 'react';
import styles from './place-distribution-profile.module.css';
import { ResponsiveBar } from '@nivo/bar';
import { autorun } from 'mobx';
import dateFormat from 'date-fns/format';
import observableFiltersStore from '../../stores/filters';
import getPlaceDistributionProfile from '../../client/get-place-distribution-profile';

interface PlaceDistributionProfileProps {
  interval: string,
  setInterval: (interval: string) => void,
  intervalName: string,
  setIntervalName: (intervalPart: string) => void,
}

const PlaceDistributionProfile: React.FC<PlaceDistributionProfileProps> = (props: PlaceDistributionProfileProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>();

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const result = await getPlaceDistributionProfile(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
      );

      if (result.success) {
        setData(result.data);
      }

      setIsLoading(false);
    });

    return () => dispose();
  }, []);

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <h2>DISTRIBUTION PROFILE</h2>
          <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
          <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
          {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
          {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
          <p>Source: Heco et al</p>
          <div className={styles.legend}>
            <div className={styles.item}>
              <div className={styles.square} style={{background: 'red'}}></div>
              <div className={styles.item}>% value</div>
            </div>
          </div>
        </div>
        <div style={{height: '40rem'}}>
          {isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
          {!isLoading && data &&
            <ResponsiveBar
              data={data.reduce((acc: any[], curr: any) => acc = [...acc, { distribution_profile: curr.distribution_profile, '% value': curr.value}], [])}
              keys={[
                '% value',
              ]}
              colors={['red']}
              indexBy="distribution_profile"
              layout="horizontal"
              onClick={(data) => {
                props.setInterval(data['indexValue'].toString());
                props.setIntervalName(data.data.distribution_profile.toString());
              }}
              margin={{ top: 10, right: 25, bottom: 0, left: 65 }}
              padding={0.4}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 10,
                tickPadding: 1,
                tickRotation: 0,
              }}
              axisLeft={{
                tickSize: 2,
                tickPadding: 10,
                tickRotation: 0,
              }}
              labelSkipWidth={12}
              labelSkipHeight={6}
              labelTextColor="black"
              theme={{
                axis: {
                  ticks: {
                    text: {
                      fontSize: 11,
                      fontWeight: 'bold',
                    },
                  },
                },
              }}
              enableGridY={false}
              role="application"
              ariaLabel="Place Distribution Profile Bar Chart"
              barAriaLabel={function(e) {
                return e.id+': '+e.formattedValue+' in merchant: '+e.indexValue;
              }}
            />
          }
        </div>
        {props.interval && props.intervalName &&
          <div className={styles.clearChoiceButton}>
            <div>Interval: {props.interval}</div>
            <button onClick={() => {
              props.setInterval('');
              props.setIntervalName('');
            }}>
              Clear choice
            </button>
          </div>
        }
      </div>
    </>
  );
};

export default PlaceDistributionProfile;
