import React from 'react';
import { useNavigate } from 'react-router-dom';
import Arrow from '../arrow/arrow';
import styles from './header.module.css';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '../menu/menu';
import IconButton  from '@mui/material/IconButton';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);

  const Logo: React.FC = () => {
    return (
      <div className={styles.logo} onClick={() => navigate('/')}>
        <Arrow fontSize="inherit" color="primary" />
        <h1>Heco et al</h1>
      </div>
    );
  };

  return (
    <>
      <div className={styles.header}>
        <Logo />
        <IconButton className={styles.menuButton} onClick={() => setIsMenuOpen(val => !val)}>
          <MenuIcon className={styles.menuIcon} color="inherit" />
        </IconButton>
      </div>
      <Menu
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        onOpen={() => setIsMenuOpen(true)}
      />
    </>
  );
};

export default Header;