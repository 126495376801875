import React from 'react';
import styles from './product-what.module.css';
import Filters from '../../components/filters/filters';
import ProductAssortment from '../../components/product-assortment/product-assortment';
import ProductSeasonality from '../../components/product-seasonality/product-seasonality';
import ProductPenetration from '../../components/product-penetration/product-penetration';
import ProductFrequency from '../../components/product-frequency/product-frequency';
import ProductTopSKUs from '../../components/product-top-skus/product-tok-skus';
import FloatingToast from '../../components/toast-for-switching-product/floating-toast';

const ProductWhat: React.FC = () => {
  return (
    <div>
      <Filters />
      <div className={styles.wrapper}>
        <div className={styles.box1}>
          <ProductAssortment />
        </div>
        <div className={styles.box2}>
          <ProductSeasonality />
        </div>
        <div className={styles.box3}>
          <ProductPenetration />
        </div>
        <div className={styles.box4}>
          <ProductFrequency />
        </div>
      </div>
      <FloatingToast bold={'what'} />
    </div>
  );
};

export default ProductWhat;
