import React from 'react';
import Filters from '../../components/filters/filters';
import styles from './market-what.module.css';
import MarketValueDriversPen from '../../components/market-value-drivers-pen/market-value-drivers-pen';
import MarketMarketShare from '../../components/market-market-share/market-market-share';
import CategorySegmentation from '../../components/category-segmentation/category-segmentation';
import CategoryDevelopmentIndex from '../../components/category-development-index/category-development-index';
import FloatingToast from '../../components/toast-for-switching-market/floating-toast';

const MarketWhat: React.FC = () => {
  return (
    <div className={styles.wrap}>
      <Filters />
      <div style={{height: '40rem'}} className={styles.content}>
        <div className={styles.left}>
          <MarketMarketShare />
          <MarketValueDriversPen /> 
          <CategoryDevelopmentIndex />
        </div>
        <div className={styles.right}>
          <div className={styles.wrap}>
            <div className={styles.header}>
              <CategorySegmentation />
            </div>
          </div>
        </div>
      </div>
      <FloatingToast bold={'what'} />
    </div>
  );
};

export default MarketWhat;
