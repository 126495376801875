import React from 'react';
import styles from './bar-chart-item.module.css';
import { ResponsiveBar } from '@nivo/bar';
import { ValueDriversModel } from '../../client/get-brand-value-drivers-one';

interface Props {
  data: ValueDriversModel[];
  legend: string;
}

const BarChartItem: React.FC<Props> = ({data, legend}) => {
  return (
    <>
      <div className={styles.graph}>
        {data && 
          <ResponsiveBar
            data={data.reduce((acc: any[], curr) => acc = [...acc, { name: curr.brand_name, 'Current period': curr.current_count, 'Last period': curr.last_year_count }], [])}
            keys={[
              'Current period',
              'Last period',
            ]}
            colors={['#0096FF', '#B2BEB5']}
            colorBy="id"
            indexBy="name"
            layout="vertical"
            ariaLabel=""
            tooltip={() => null}
            padding={0.2}
            enableGridX={false}
            enableGridY={false}
            groupMode="grouped"
            axisBottom={{
              legend: legend ? legend : '',
              legendPosition: 'middle',
              legendOffset: 39,
            }}
            margin={
              {
                bottom: 47,
                top: 0,
              }
            }
          />
        }
      </div>
    </>
  );
};

export default BarChartItem;