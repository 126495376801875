import { CategorySegmentationModel } from '../../client/get-category-segmentation';

const formatDataForCategorySegmentation = (data: CategorySegmentationModel[]) => {

  const formattedData = data.map(merchantObject => {
    return {
      'id': merchantObject.category_name,
      'data': [{x: merchantObject.penetration, y: merchantObject.frequency}],
    };
  });

  return formattedData;
};

export default formatDataForCategorySegmentation;
