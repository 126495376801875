import React from 'react';
import styles from './price-elasticity.module.css';
import { autorun } from 'mobx';
import dateFormat from 'date-fns/format';
import { ResponsiveLine } from '@nivo/line';
import observableFiltersStore from '../../stores/filters';
import getPriceElasticity from '../../client/get-price-elasticity';

const PriceElasticity: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>();

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const result = await getPriceElasticity(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
      );

      if (result.success) {
        setData(result.data);
      }
      setIsLoading(false);
    });

    return () => dispose();
  }, []);

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <h2>PRICE ELASTICITY</h2>
          <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
          <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
          {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
          {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
          <p>Source: Heco et al</p>
          <div className={styles.legend}>
            <div className={styles.item}>
              <div className={styles.square} style={{background: '#24B8FD'}}></div>
              <div className={styles.item}>Value per shopper</div>
            </div>
          </div>
        </div>
        <div className={styles.graph}>
          {isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
          {!isLoading && data &&
            <ResponsiveLine
              data={data}
              colors={['#24B8FD']}
              margin={{ top: 50, right: 25, bottom: 50, left: 50 }}
              xScale={{ type: 'linear' }}
              yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false,
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Price elasticity',
                legendOffset: 36,
                legendPosition: 'middle',
              }}
              pointSize={0}
              pointColor={{ theme: 'background' }}
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabelYOffset={-12}
              useMesh={true}
            />
          }
        </div>
      </div>
    </>
  );
};

export default PriceElasticity;
