import { LossAndGainLastTableColumns, MerchantLossAndGain } from '../../containers/category-where-why/category-where-why';

const prepareDataForLossAndGain = (matrix: Record<string, LossAndGainLastTableColumns>) => {

  const data: MerchantLossAndGain[] = [];

  for (const merchantRowName in matrix) {
    const merchantColumns = matrix[merchantRowName];
    const objectForMerchant: MerchantLossAndGain = {
      'Merchant': merchantRowName,
      'Gained to category in %': 0,
      'Gain from market in %': 0,
      'Loss to market in %': 0,
      'Lost from category in %': 0,
      '+/- Growth in %': 0,
    };
    let total = 0;
    for (const merchantColumnName in merchantColumns) {
      if (merchantColumnName === 'Gain in %') {
        objectForMerchant['Gain from market in %'] = merchantColumns[merchantColumnName];
        total += objectForMerchant['Gain from market in %'];
      } else if (merchantColumnName === 'Loss in %') {
        objectForMerchant['Loss to market in %'] = merchantColumns[merchantColumnName];
        total += objectForMerchant['Loss to market in %'];
      } else if (merchantColumnName === 'Increase in %') {
        objectForMerchant['Gained to category in %'] = merchantColumns[merchantColumnName];
        total += objectForMerchant['Gained to category in %'];
      } else if (merchantColumnName === 'Decrease in %') {
        objectForMerchant['Lost from category in %'] = merchantColumns[merchantColumnName];
        total += objectForMerchant['Lost from category in %'];
      }
    }
    objectForMerchant['+/- Growth in %'] = total;
    data.push(objectForMerchant);
  }

  data.sort((e1: MerchantLossAndGain, e2: MerchantLossAndGain) => {
    if (e1['+/- Growth in %'] < e2['+/- Growth in %']) {
      return 1;
    } else if (e1['+/- Growth in %'] > e2['+/- Growth in %']) {
      return -1;
    }
    return 0;
  });

  return data;
};

export default prepareDataForLossAndGain;
