import React from 'react';
import styles from './price-segments.module.css';
import { ResponsiveBar } from '@nivo/bar';
import { autorun } from 'mobx';
import dateFormat from 'date-fns/format';
import observableFiltersStore from '../../stores/filters';
import getPriceSegments from '../../client/get-price-segments';

let onChangeTimeout: NodeJS.Timeout;

const PriceSegments: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>();
  const [intervalStart, setIntervalStart] = React.useState<string>('0');

  const options = ['5', '10', '20', '50', '100', '200', '500', '1000'];

  const onOptionChangeHandler = (event: any) => {
    observableFiltersStore.setIntervalLength(event.target.value);
  };
  const onInputChangeHandler = (event: any) => {
    setIntervalStart(event.target.value);
    if (onChangeTimeout) {
      clearTimeout(onChangeTimeout);
    }
    onChangeTimeout = setTimeout(() => {
      observableFiltersStore.setIntervalStart(event.target.value);
    }, 1000);
  };

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);
      const result = await getPriceSegments(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.intervalStart,
        observableFiltersStore.intervalLength,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
      );

      if (result.success) {
        setData(result.data);
      }
      setIsLoading(false);
    });

    return () => dispose();
  }, []);

  return (
    <>
      <div className={styles.wrap}>
        <div>
          <div className={styles.header}>
            <h2>PRICE SEGMENTS</h2>
            <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
          </div>
          <div className={styles.optionDiv}>
            <div>
              <label className={styles.intervalStartLabel}>Interval start: </label>
              <input className={styles.intervalStartInput} value={intervalStart} type="number" min="0" max="100000" onChange={onInputChangeHandler} /> <br/>
            </div>
            <div>
              <label className={styles.intervalLengthLabel}>Interval length: </label>
              <select defaultValue={'5'} onChange={onOptionChangeHandler}>
                {options.map((option, index) => <option key={index} >{option}</option>)}
              </select>
            </div>
          </div>
          <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
          {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
          <p>Source: Heco et al</p>
        </div>
        <div className={styles.graph}>
          {isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
          {!isLoading && data && data.brandNames &&
            <ResponsiveBar
              data={data.data}
              keys={data.brandNames}
              indexBy="interval"
              margin={{ top: 30, right: 130, bottom: 30, left: 20 }}
              padding={0.4}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={data.colors}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 10,
                tickPadding: 1,
                tickRotation: 0,
              }}
              axisLeft={{
                tickSize: 2,
                tickPadding: 1,
                tickRotation: 0,
              }}
              labelSkipWidth={12}
              labelSkipHeight={6}
              labelTextColor="black"
              theme={{
                axis: {
                  ticks: {
                    text: {
                      fontSize: 11,
                      fontWeight: 'bold',
                    },
                  },
                },
              }}
              enableGridY={false}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'top-right',
                  direction: 'column',
                  justify: false,
                  translateX: 110,
                  translateY: -15,
                  itemsSpacing: 3,
                  itemWidth: 116,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 14,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Price Segments Bar Chart"
              barAriaLabel={function(e) {
                return e.id+': '+e.formattedValue+' in merchant: '+e.indexValue;
              }}
            />
          }
        </div>
      </div>
    </>
  );
};

export default PriceSegments;
