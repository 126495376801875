import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import Logo from '../../components/logo/logo';
import styles from './forgot-password-userid-code.module.css';
import Arrow from '../../components/arrow/arrow';
import Alert from '@mui/material/Alert';
import { useNavigate, useParams } from 'react-router-dom';
import resetPassword from '../../client/reset-password';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import observableUiStore from '../../stores/ui';

const ForgotPasswordUserIdCode: React.FC = () => {
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { email, userId, code } = useParams();
  const navigate = useNavigate();

  const submit = async () => {
    if (!email || !userId || !code) return setError('Invalid parameters.');
    if (!password) return setError('Password is a required field.');
    if (password !== repeatPassword) return setError('Passwords do not match.');
    if (!password.match('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')) {
      return setError('Password must include at least one uppercase letter, one lowercase letter, one number, and be at least 8 characters long.');
    }

    setError('');
    observableUiStore.setIsLoading(true);
    try {
      const result = await resetPassword(userId, password, code);
      if (result.success === true) {
        const auth = getAuth();
        await signInWithEmailAndPassword(auth, email, password);
        observableUiStore.setIsLoading(false);
        navigate('/', {replace: true});
      } else {
        observableUiStore.setIsLoading(false);
        setError(result.error || 'An unexpected error has occurred. Please try again.');
      }
    } catch (e) {
      observableUiStore.setIsLoading(false);
      setError(e.message || 'An unexpected error has occurred. Please try again.');
    }
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <Logo className={styles.logo} />
        
        <TextField className={styles.input} variant="outlined" value={password} type="password" label="New password" onChange={e => setPassword(e.target.value)} fullWidth />
        <TextField className={styles.input} variant="outlined" value={repeatPassword} type="password" label="Repeat password" onChange={e => setRepeatPassword(e.target.value)} fullWidth />
        
        <Button className={styles.button} fullWidth variant="contained" color="secondary" startIcon={<Arrow color="primary" />} onClick={submit}>Reset password</Button>
        
        {error ? <Alert severity="error" className={styles.alert}>{error}</Alert> : null}
      </div>
    </div>
  );
};

export default ForgotPasswordUserIdCode;