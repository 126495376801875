import React from 'react';
import styles from './category-value-drivers.module.css';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import observableFiltersStore from '../../stores/filters';
import getCategoryValueDrivers, { CategoryValueDriversResult } from '../../client/get-category-value-drivers';
import dateFormat from 'date-fns/format';
import TreeItem from '../tree-item/tree-item';

const CategoryValueDrivers: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<CategoryValueDriversResult>();

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);
      const result = await getCategoryValueDrivers(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
      );

      setIsLoading(false);
      if (result.success) {
        setData(result.data);
      }
    });

    return () => dispose();
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2>CATEGORY VALUE DRIVERS</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
        {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
        {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
        <p>Source: Heco et al</p>
      </div>
      <div className={styles.tree}>
        <ul>
          <li>
            <p>
              <span>Category Value</span>
              { 
                !isLoading && data ?
                  <TreeItem
                    measureSign=" kr."
                    maximumFractionDigits={0}
                    currentValue={data.current.categoryValue}
                    lastYearPeriod={data.lastYearPeriod.categoryValue}
                  />
                  : 
                  '...'
              }
            </p>
            <ul>
              <li>
                <p>
                  <span>Value per shopper</span>
                  { 
                    !isLoading && data ?
                      <TreeItem
                        measureSign=" kr."
                        maximumFractionDigits={2}
                        currentValue={data.current.valuePerShopper}
                        lastYearPeriod={data.lastYearPeriod.valuePerShopper}
                      />
                      : 
                      '...'
                  }
                </p>
                <ul>
                  <li>
                    <p>
                      <span>Frequency</span>
                      {
                        !isLoading && data ?
                          <TreeItem
                            measureSign=""
                            maximumFractionDigits={3}
                            currentValue={data.current.frequency}
                            lastYearPeriod={data.lastYearPeriod.frequency}
                          />
                          : 
                          '...'
                      }
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Value per trip</span>
                      {
                        !isLoading && data ?
                          <TreeItem
                            measureSign=" kr."
                            maximumFractionDigits={3}
                            currentValue={data.current.valuePerTrip}
                            lastYearPeriod={data.lastYearPeriod.valuePerTrip}
                          />
                          : 
                          '...'
                      }
                    </p>
                    <ul>
                      <li>
                        <p>
                          <span>Units</span>
                          {
                            !isLoading && data ?
                              <TreeItem
                                measureSign=""
                                maximumFractionDigits={3}
                                currentValue={data.current.units}
                                lastYearPeriod={data.lastYearPeriod.units}
                              />
                              : 
                              '...'
                          }
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>Average price</span>
                          {
                            !isLoading && data ?
                              <TreeItem
                                measureSign=" kr."
                                maximumFractionDigits={3}
                                currentValue={data.current.averagePrice}
                                lastYearPeriod={data.lastYearPeriod.averagePrice}
                              />
                              : 
                              '...'
                          }
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <span>Category shoppers</span>
                  {
                    !isLoading && data ?
                      <TreeItem
                        measureSign=""
                        maximumFractionDigits={0}
                        currentValue={data.current.categoryShoppers}
                        lastYearPeriod={data.lastYearPeriod.categoryShoppers}
                      />
                      : 
                      '...'
                  }
                </p>
                <ul>
                  <li>
                    <p>
                      <span>Shoppers</span>
                      {
                        !isLoading && data ?
                          <TreeItem
                            measureSign="lx"
                            maximumFractionDigits={2}
                            currentValue={data.current.shoppers * 100 / data.lastYearPeriod.shoppers}
                            lastYearPeriod={data.lastYearPeriod.shoppers}
                            shoppers={data.current.shoppers}
                          />
                          : 
                          '...'
                      }
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Category Penetration</span>
                      {
                        !isLoading && data ?
                          <TreeItem
                            measureSign="%"
                            maximumFractionDigits={2}
                            currentValue={data.current.penetration}
                            lastYearPeriod={data.lastYearPeriod.penetration}
                          />
                          : 
                          '...'
                      }
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default observer(CategoryValueDrivers);