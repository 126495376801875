import React from 'react';
import styles from './market-who.module.css';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import Filters from '../../components/filters/filters';
import observableFiltersStore from '../../stores/filters';
import TransitionProfile from '../../components/transition-profile-for-market-who/transition-profile';
import Geography from '../../components/product-geography/geography';
import Age from '../../components/product-age/age';
import Consumption from '../../components/product-consumption/consumption';
import FamilyType from '../../components/product-family-type/family-type';
import BasketSize from '../../components/brand-and-category-basket-size/basket-size';
import FloatingToast from '../../components/toast-for-switching-market/floating-toast';
import getMarketUserData, {MarketUserDataModel} from '../../client/get-market-user-data';

const MarketWho: React.FC = () => {
  const [functionality, setFunctionality] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<MarketUserDataModel>();

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const result = await getMarketUserData(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
        functionality,
      );

      if (result.success) {
        setData(result.data);
      }

      setIsLoading(false);
    });

    return () => dispose();
  }, [functionality]);
  return (
    <div>
      <Filters />
      <div className={styles.wrapper}>
        <div className={styles.box1}>
          <TransitionProfile functionality={functionality} setFunctionality={setFunctionality} />
        </div>
        <div className={styles.box2}>
          <Geography isMarketWho={true} isLoading={isLoading} name={'Functionality'} value={functionality} data={data && data.geography ? data.geography : []} />
        </div>
        <div className={styles.box3}>
          <Age isMarketWho={true} isLoading={isLoading} name={'Functionality'} value={functionality} data={data && data.age ? data.age : []} />
        </div>
        <div className={styles.box4}>
          <Consumption isMarketWho={true} isLoading={isLoading} name={'Functionality'} value={functionality} data={data && data.consumption ? data.consumption : []} />
        </div>
        <div className={styles.box5}>
          <FamilyType isMarketWho={true} isLoading={isLoading} name={'Functionality'} value={functionality} data={data && data.familyType ? data.familyType : []} />
        </div>
        <div className={styles.box6}>
          <BasketSize isMarketWho={true} isLoading={isLoading} name={'Functionality'} value={functionality} data={data && data.basketSize ? data.basketSize : []} />
        </div>
      </div>
      <FloatingToast bold={'who'} />
    </div>
  );
};

export default observer(MarketWho);