const config = {
  firebaseConfig: {
    apiKey: 'AIzaSyCl5mZtO76VsNSXUiq1A_TgE85hhQa6vOM',
    authDomain: 'heco-et-al-dashboard.firebaseapp.com',
    projectId: 'heco-et-al-dashboard',
    storageBucket: 'heco-et-al-dashboard.appspot.com',
    messagingSenderId: '604321073807',
    appId: '1:604321073807:web:0039d19badb2363f6cfefa',
  },
};

export default config;