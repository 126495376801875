interface MerchantSharing {
  'Merchant': string,
  'Shared': number,
  'Sharing': number,
  'Whitespace': number,
}

const formatDataForMerchantSharing = (merchant: string, merchantSharing: Record<string, number>) => {
  
  const formatedData: [string, number][] = Object.entries(merchantSharing);
  let whiteSpaceLeft = merchantSharing[merchant];

  formatedData.sort((array1: [string, number], array2: [string, number]) => { // Array of arrays, where the inner arrays contain only 2 elements, 1st a string, 2nd a number 
    if (array1[1] > array2[1]) {
      return -1;
    }
    if (array1[1] < array2[1]) {
      return 1;
    }
    return 0;
  });

  const index = formatedData.findIndex(array => array[0] === merchant);
  const merchantSharingFrom = formatedData[index];
  formatedData.splice(index, 1);
  formatedData.splice(0, 0, merchantSharingFrom);
  
  const data: MerchantSharing[] = [];
  for (let i = 0; i < formatedData.length; i++) {
    formatedData[i][1] = Math.round(100 * formatedData[i][1]) / 100;
    if (formatedData[i][0] === merchant) {
      data.push({
        'Merchant': merchant,
        'Sharing': formatedData[i][1],
        'Shared': 0,
        'Whitespace': 0,
      });
      continue;
    };
    if (formatedData[i][1] === 0) break;
    const objectForMerchant: MerchantSharing = {
      'Merchant': formatedData[i][0],
      'Sharing': 0,
      'Shared': formatedData[i][1],
      'Whitespace': whiteSpaceLeft > 0 ? (whiteSpaceLeft - formatedData[i][1]) : 0,
    };
    data.push(objectForMerchant);
    whiteSpaceLeft = data[data.length - 1]['Whitespace'];
  };

  return data;
};

export default formatDataForMerchantSharing;
