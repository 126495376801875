import { getAuth } from 'firebase/auth';
import { Result } from './models';
import dateFormat from 'date-fns/format';

export interface PromotionWeeksValuePerShopperResultObject { 
  week_number: string;
  average_value: number;
}

const getPromotionWeeksValuePerShopper = async (dateFrom: Date, dateTo: Date, categoryId?: string, merchantIds?: string[], brandIds?: string[]): Promise<Result<PromotionWeeksValuePerShopperResultObject[]>> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();

  if (!token) {
    throw new Error('Unauthorized');
  }
  
  const response = await fetch(`${process.env.REACT_APP_API}/v1/get-promotion-weeks-value-per-shopper?date_from=${dateFormat(dateFrom, 'yyyy-MM-dd')}&date_to=${dateFormat(dateTo, 'yyyy-MM-dd')}&category_id=${categoryId || ''}&merchant_ids=${merchantIds?.reduce((acc: string[], curr) => acc = [...acc, encodeURIComponent(curr)], [])?.join(',') || ''}&brand_ids=${brandIds?.reduce((acc: string[], curr) => acc = [...acc, encodeURIComponent(curr)], [])?.join(',') || ''}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
  return await response.json();
};

export default getPromotionWeeksValuePerShopper;
