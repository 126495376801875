const brandSharing = (brandSharingName: string, brandsSharing: Record<string, Record<string, number>>) => {

  const brandSharing: Record<string, number> = {};
  let sumSharing = 0;

  for (const brandColumnName of Object.keys(brandsSharing[brandSharingName])) {
    brandSharing[brandColumnName] = brandsSharing[brandSharingName][brandColumnName];
    sumSharing += brandSharing[brandColumnName];
  }

  brandSharing[brandSharingName] = sumSharing;

  return brandSharing;
};

export default brandSharing;
