import React from 'react';
import styles from './consumption.module.css';
import { observer } from 'mobx-react-lite';
import observableFiltersStore from '../../stores/filters';
import dateFormat from 'date-fns/format';
import { ResponsiveBar } from '@nivo/bar';
import formatDataForConsumptionChart from './formatDataForConsumptionChart';

interface ConsumptionProps {
  isLoading: boolean;
  data: any;
  value?: string;
  name: string;
  value2?: string;
  name2?: string;
  isMarketWho?: boolean;
}

const Consumption: React.FC<ConsumptionProps> = (props: ConsumptionProps) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2>CONSUMPTION</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        {!props.isMarketWho && <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>}
        {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
        {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
        {props.value2 ? <p>{props.name2}: {props.value2}</p> : ''}
        {props.value ? <p>{props.name}: {props.value}</p> : ''}
        <p>Source: Heco et al</p>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#FE872B'}}></div>
            <div className={styles.item}>1 pr. 360 days</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#A3E2FF'}}></div>
            <div className={styles.item}>1 pr. 30 days</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#FF9D2C'}}></div>
            <div className={styles.item}>1 pr. 180 days</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#21B8FE'}}></div>
            <div className={styles.item}>1 pr. 2 weeks</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#FFC62F'}}></div>
            <div className={styles.item}>1 pr. 90 days</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#0096FF'}}></div>
            <div className={styles.item}>1 pr. week</div>
          </div>
        </div>
      </div>
      <div className={styles.graph}>
        {props.isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
        {!props.isLoading && props.data &&
          <ResponsiveBar
            data={formatDataForConsumptionChart(props.data)}
            keys={[
              '1 pr. week',
              '1 pr. 2 weeks',
              '1 pr. 30 days',
              '1 pr. 90 days',
              '1 pr. 180 days',
              '1 pr. 360 days',
            ]}
            labelTextColor="white"
            colors={['#0096FF', '#21B8FE', '#A3E2FF', '#FFC62F', '#FF9D2C', '#FE872B']}
            indexBy="id"
            margin={{ top: 20, right: 25, bottom: 50, left: 50 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={[
              {
                match: {
                  id: 'fries',
                },
                id: 'dots',
              },
              {
                match: {
                  id: 'sandwich',
                },
                id: 'lines',
              },
            ]}
            borderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6,
                ],
              ],
            }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Percentage',
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            role="application"
            ariaLabel="Consumption Bar Chart"
            barAriaLabel={function(e) {
              return e.id+': '+e.formattedValue+' in country: '+e.indexValue;
            }}
          />
        }
      </div>
    </div>
  );
};

export default observer(Consumption);
