import { getAuth } from 'firebase/auth';
import {Result} from './models';
import dateFormat from 'date-fns/format';

export interface ValueDriversModel {
  merchant_id: string;
  last_year_count: number;
  current_count: number;
}

export interface ValueDriversModelResultOne {
  penetration: ValueDriversModel[];
  frequency: ValueDriversModel[];
  basket: ValueDriversModel[];
}

const getMarketValueDriversOne = async (dateFrom: Date, dateTo: Date, merchantIds?: string[], count?: number): Promise<Result<ValueDriversModelResultOne>> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();

  if (!token) {
    throw new Error('Unauthorized');
  }
  
  const response = await fetch(`${process.env.REACT_APP_API}/v1/get-market-value-drivers-one?date_from=${dateFormat(dateFrom, 'yyyy-MM-dd')}&date_to=${dateFormat(dateTo, 'yyyy-MM-dd')}&merchant_ids=${merchantIds?.reduce((acc: string[], curr) => acc = [...acc, encodeURIComponent(curr)], [])?.join(',') || ''}&count=${count}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
  return await response.json();
};

export default getMarketValueDriversOne;