import {Result} from './models';

const requestPasswordReset = async (email: string): Promise<Result> => {
  const response = await fetch(`${process.env.REACT_APP_API}/v1/request-password-reset`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
  });
  return await response.json();
};

export default requestPasswordReset;