import React from 'react';
import styles from './promotion-where-why.module.css';
import Filters from '../../components/filters/filters';
import PromotionWeeksPenetration from '../../components/promotion-weeks-penetration/promotion-weeks-penetration';
import PromotionWeeksFrequency from '../../components/promotion-weeks-frequency/promotion-weeks-frequency';
import PromotionWeeksInitiatives from '../../components/promotion-weeks-initiatives/promotion-weeks-initiatives';
import PromotionWeeksValuePerShopper from '../../components/promotion-weeks-value-per-shopper/promotion-weeks-value-per-shopper';
import FloatingToast from '../../components/toast-for-switching-promotion/floating-toast';

const PromotionWhereWhy: React.FC = () => {
  return (
    <div>
      <Filters />
      <div className={styles.wrapper}>
        <div className={styles.box1}>
          <PromotionWeeksPenetration />
        </div>
        <div className={styles.box2}>
          <PromotionWeeksFrequency />
        </div>
        <div className={styles.box3}>
          <PromotionWeeksInitiatives />
        </div>
        <div className={styles.box4}>
          <PromotionWeeksValuePerShopper />
        </div>
      </div>
      <FloatingToast bold={'where-why'} />
    </div>
  );
};

export default PromotionWhereWhy;
