import React from 'react';
import styles from './market-where-why.module.css';
import Filters from '../../components/filters/filters';
import FloatingToast from '../../components/toast-for-switching-market/floating-toast';
import MarketPenetrationFlow from '../../components/market-penetration-flow/market-penetration-flow';
import TopCategoriesAndBrands from '../../components/market-top-categories-and-brands/market-top-categories-and-brands';

const BrandWhereWhy: React.FC = () => {

  return (
    <div className={styles.outsideContainer}>
      <Filters />
      <div className={styles.wrapper}>
        <div className={styles.box1}>
          <MarketPenetrationFlow />
        </div>
        <div className={styles.box2}>
          <TopCategoriesAndBrands />
        </div>
      </div>
      <FloatingToast bold={'where-why'} />
    </div>
  );
};

export default BrandWhereWhy;
