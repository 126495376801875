interface BrandGainMarket {
  'Brand': string,
  'Gained': number,
  'Lost': number,
  'Whitespace': number,
}

interface BrandGainData{
  brandGainFromMarket: Record<string, number>,
  sumGainFromMarket: number,
}

const formatDataForBrandGainFromMarket = (brand: string, brandGainData: BrandGainData) => {
  
  const formatedData: [string, number][] = Object.entries(brandGainData.brandGainFromMarket);
  const sumGainFromMarket = brandGainData.sumGainFromMarket;
  let whiteSpaceLeft = sumGainFromMarket;

  formatedData.sort((array1: [string, number], array2: [string, number]) => { // Array of arrays, where the inner arrays contain only 2 elements, 1st a string, 2nd a number 
    if (array1[1] > array2[1]) {
      return -1;
    }
    if (array1[1] < array2[1]) {
      return 1;
    }
    return 0;
  });

  const index = formatedData.findIndex(array => array[0] === brand);
  const brandGaining = formatedData[index];
  formatedData.splice(index, 1);
  formatedData.splice(0, 0, brandGaining);
  
  const data: BrandGainMarket[] = [];
  for (let i = 0; i < formatedData.length; i++) {
    if (formatedData[i][0] === brand) {
      data.push({
        'Brand': brand,
        'Gained': sumGainFromMarket,
        'Lost': 0,
        'Whitespace': 0,
      });
      continue;
    };
    if (formatedData[i][1] === 0) break;
    const objectForBrand: BrandGainMarket = {
      'Brand': formatedData[i][0],
      'Gained': 0,
      'Lost': Number(formatedData[i][1]),
      'Whitespace': whiteSpaceLeft > 0 ? (whiteSpaceLeft - Number(formatedData[i][1])) : 0,
    };
    data.push(objectForBrand);
    whiteSpaceLeft = data[data.length - 1]['Whitespace'];
  };

  return data;
};

export default formatDataForBrandGainFromMarket;
