import { LossAndGainLastTableColumns } from '../../../containers/category-where-why/category-where-why';

const merchantLostFromCategory = (merchant: string, matrix: Record<string, LossAndGainLastTableColumns>) => {
  
  return [{
    'Merchant': merchant,
    'Lost': matrix[merchant]['Decrease in %'],
    'Gained': 0,
  }];
};

export default merchantLostFromCategory;
