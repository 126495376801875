import React from 'react';
import { autorun } from 'mobx';
import Filters from '../../components/filters/filters';
import observableFiltersStore from '../../stores/filters';
import styles from './price-what.module.css';
import FloatingToast from '../../components/toast-for-switching-price/floating-toast';
import PriceSegments from '../../components/price-segments/price-segments';
import BrandsAveragePrices from '../../components/brands-average-prices/brands-average-prices';
import BrandsMaxPrices from '../../components/brands-max-prices/brands-max-prices';
import BrandsMinPrices from '../../components/brands-min-prices/brands-min-prices';
import getBrandsMaxAndMinPrices from '../../client/get-brands-max-and-min-prices';
import BrandsPromoPrices from '../../components/brands-promo-prices/brands-promo-prices';
import PriceElasticity from '../../components/price-elasticity/price-elasticity';

const PriceWhat: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>();

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const result = await getBrandsMaxAndMinPrices(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
      );

      if (result.success) {
        setData(result.data);
      }
      setIsLoading(false);
    });

    return () => dispose();
  }, []);

  return (
    <div>
      <Filters />
      <div className={styles.wrapper}>
        <div className={styles.box1}>
          <PriceElasticity />
        </div>
        <div className={styles.box2}>
          <PriceSegments />
        </div>
        <div className={styles.box3}>
          <BrandsMaxPrices isLoading={isLoading} dataBrandsMaxPrices={data && data.dataBrandsMaxPrices ? data.dataBrandsMaxPrices : []} colors={data ? data.colors : []} brandNames={data ? data.brandNames : []} />
        </div>
        <div className={styles.box4}>
          <BrandsAveragePrices />
        </div>
        <div className={styles.box5}>
          <BrandsMinPrices isLoading={isLoading} dataBrandsMinPrices={data && data.dataBrandsMinPrices ? data.dataBrandsMinPrices : []} colors={data ? data.colors : []} brandNames={data ? data.brandNames : []} />
        </div>
        <div className={styles.box6}>
          <BrandsPromoPrices />
        </div>
      </div>
      <FloatingToast bold={'what'} />
    </div>
  );
};

export default PriceWhat;
