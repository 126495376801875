import React from 'react';
import styles from './category-development-index.module.css';
import { observer } from 'mobx-react-lite';
import observableFiltersStore from '../../stores/filters';
import { autorun } from 'mobx';
import dateFormat from 'date-fns/format';
import getCategoryDevelopmentIndex, { CategoryDevelopmentModel } from '../../client/get-category-development-index';
import BarChartItem from '../market-bar-chart-item/market-bar-chart-item';

const CategoryDevelopmentIndex: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<CategoryDevelopmentModel[]>([]);

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);
      const result = await getCategoryDevelopmentIndex(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
      );

      if (result.success) {
        setData(result.data);
        setIsLoading(false);
      }
    });

    return () => dispose();
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2>CATEGORY DEVELOPMENT INDEX</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        <p>Source: Heco et al</p>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#0096FF'}}></div>
            <div className={styles.item}>Current period</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#B2BEB5'}}></div>
            <div className={styles.item}>Last period</div>
          </div>
        </div>
      </div>

      <div className={styles.graph}>
        <div className={styles.containerPen}>
          {isLoading || !data ?
            <h3 className={styles.isLoading}>Loading...</h3> : 
            <BarChartItem data={data} legend={'Category Development Index'}/>
          }
        </div>
      </div>
    </div>
  );
};

export default observer(CategoryDevelopmentIndex);
