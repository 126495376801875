import { LossAndGainLastTableColumns } from '../../../containers/brand-where-why/brand-where-why';

const brandGainedToCategory = (brand: string, matrix: Record<string, LossAndGainLastTableColumns>) => {
  
  return [{
    'Brand': brand,
    'Gained': matrix[brand]['Increase in %'],
    'Lost': 0,
  }];
};

export default brandGainedToCategory;
