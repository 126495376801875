import React, { useEffect, useState } from 'react';
import { autorun } from 'mobx';
import styles from './place-market-sales-per-shopper.module.css';
import dateFormat from 'date-fns/format';
import observableFiltersStore from '../../stores/filters';
import { ResponsiveBar } from '@nivo/bar';
import getPlaceMarketSalesPerShopper from '../../client/get-place-market-sales-per-shopper';

const PlaceMarketSalesPerShopper: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const result = await getPlaceMarketSalesPerShopper(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
      );

      if (result.success) {
        setData(result.data);
      }

      setIsLoading(false);
    });

    return () => dispose();
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2>SALES PER SHOPPER</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
        {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
        {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
        <p>Source: Heco et al</p>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#0096FF'}}></div>
            <div className={styles.item}>Category in units/period/user</div>
          </div>
        </div>
      </div>
      <div className={styles.graph}>
        {isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
        {!isLoading && data &&
          <ResponsiveBar
            data={data.reduce((acc: any[], curr) => acc = [...acc, { Retailer: curr.merchant_id[0].toUpperCase() + curr.merchant_id.slice(1).toLowerCase(), 'Category in units/period/user': Math.round(100 * curr.average) / 100 }], [])}
            keys={[
              'Category in units/period/user',
            ]}
            colors={['#0096FF']}
            colorBy="id"
            indexBy="Retailer"
            layout="vertical"
            indexScale={{ type: 'band', round: false }}
            padding={0.2}
            enableGridX={false}
            enableGridY={false}
            groupMode="grouped"
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Retailer',
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: data.length > 20 ? 8 : data.length > 15 ? 10 : 12,
                    fontWeight: 'bold',
                  },
                },
              },
            }}
            margin={
              {
                bottom: 47,
                top: 40,
              }
            }
          />
        }
      </div>
    </div>
  );
};

export default PlaceMarketSalesPerShopper;
