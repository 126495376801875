import React from 'react';
import { Link } from 'react-router-dom';
import styles from './floating-toast.module.css';

interface Props {
  bold: string,
}

const FloatingToast: React.FC<Props> = (props: Props) => {
  return (
    <div 
      className={styles.nextPage}>
      <Link
        style={{ fontWeight: props.bold === 'what' ? '500' : '400' }}
        to="/promotion-what"
      >
        What
      </Link>
      <span>{` | `}</span>
      <Link
        style={{ fontWeight: props.bold === 'where-why' ? '500' : '400' }}
        to="/promotion-where-why"
      >
        Where-why
      </Link>
      <span>{` | `}</span>
      <Link
        style={{ fontWeight: props.bold === 'who' ? '500' : '400' }}
        to="/promotion-who"
      >
        Who
      </Link>
    </div>
  );
};

export default FloatingToast;
