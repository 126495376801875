import React, { useState } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import observableFiltersStore from '../../stores/filters';
import styles from './brand-where-why.module.css';
import Filters from '../../components/filters/filters';
import FloatingToast from '../../components/toast-for-switching-brand/floating-toast';
import getBrandLossAndGain from '../../client/get-brand-loss-and-gain';
import calculateBrandMatrix from '../../components/brand-loss-and-gain/calculateBrandMatrix';
import prepareDataForBrandLossAndGain from '../../components/brand-loss-and-gain/prepareDataForBrandLossAndGain';
import BrandLossAndGain from '../../components/brand-loss-and-gain/brand-loss-and-gain';
import getBrandDuplication from '../../client/get-brand-duplication';
import BrandDuplication from '../../components/brand-duplication/brand-duplication';
import BrandTransition from '../../components/brand-transition/brand-transition';
import BrandTopAssortment from '../../components/brand-top-assortment/brand-top-assortment';

export interface LossAndGainLastTableColumns {
  P1: number,
  P2: number,
  Calc: number,
  'Calc in %': number,
  Gain: number, 
  'Gain in %': number,
  Loss: number,
  'Loss in %': number,
  Increase: number, 
  'Increase in %': number,
  Decrease: number,
  'Decrease in %': number,
}

export interface BrandLossAndGainInterface {
  'Brand': string,
  'Gained to category in %': number,
  'Gain from market in %': number,
  'Loss to market in %': number,
  'Lost from category in %': number,
  '+/- Growth in %': number,
}

export interface BrandDuplicationInterface {
  'Brand': string,
  'Only at': number,
  'Sharing': number,
  'Elsewhere': number,
  'Not in the shop': number,
}

export interface BrandDuplicationAndTransition {
  dataDuplication: BrandDuplicationInterface[],
  brandsSharing: Record<string, Record<string, number>>,
  brandsElsewhere: Record<string, Record<string, number>>,
}

const BrandWhereWhy: React.FC = () => {
  const [isLoadingBrandLossAndGain, setIsLoadingBrandLossAndGain] = useState<boolean>(true);
  const [isLoadingDuplication, setIsLoadingDuplication] = useState<boolean>(true);
  const [rawData, setRawData] = useState<Record<string, Record<string, number>>>();
  const [matrix, setMatrix] = useState<Record<string, LossAndGainLastTableColumns>>();
  const [dataBrandLossAndGain, setDataBrandLossAndGain] = useState<BrandLossAndGainInterface[]>();
  const [topSellingBrands, setTopSellingBrands] = useState<string[]>();
  const [brand, setBrand] = useState<string | undefined>();
  const [brand2, setBrand2] = useState<string | undefined>();
  const [typeOfChart, setTypeOfChart] = useState<string | undefined>();
  const [dataDuplicationAndTransition, setDataDuplicationAndTransition] = useState<BrandDuplicationAndTransition>();

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoadingBrandLossAndGain(true);
      setIsLoadingDuplication(true);

      const resultBrandLossAndGain = await getBrandLossAndGain(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
      );

      setBrand(undefined);
      setTypeOfChart(undefined);

      if (resultBrandLossAndGain.success) {
        setRawData({...resultBrandLossAndGain.data.lossAndGain});
        setTopSellingBrands(resultBrandLossAndGain.data.topSellingBrands);

        const matrix = calculateBrandMatrix({...resultBrandLossAndGain.data.lossAndGain});
        setMatrix(matrix);
  
        setDataBrandLossAndGain(() => prepareDataForBrandLossAndGain({...matrix}));
      }

      setIsLoadingBrandLossAndGain(false);

      const resultDuplication = await getBrandDuplication(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
      );

      if (resultDuplication.success) {
        setDataDuplicationAndTransition(resultDuplication.data);
      }

      setIsLoadingDuplication(false);
    });

    return () => dispose();
  }, []);

  return (
    <div className={styles.mainContainer}>
      <Filters />
      <div className={styles.topContainer}>
        <div className={styles.topsChild}>
          <BrandLossAndGain brand={brand} typeOfChart={typeOfChart} isLoading={isLoadingBrandLossAndGain} setBrand={setBrand} setBrand2={setBrand2} setTypeOfChart={setTypeOfChart} data={dataBrandLossAndGain ? dataBrandLossAndGain : []} topSellingBrands={topSellingBrands ? topSellingBrands : []} />
        </div>
        <div className={styles.topsChild}>
          <BrandDuplication brand={brand} typeOfChart={typeOfChart} isLoading={isLoadingDuplication} setBrand={setBrand} setBrand2={setBrand2} setTypeOfChart={setTypeOfChart} data={dataDuplicationAndTransition ? dataDuplicationAndTransition.dataDuplication : []} topSellingBrands={topSellingBrands ? topSellingBrands : []} />
        </div>
      </div>
      <div className={styles.middleContainer}>
        {rawData && matrix && dataDuplicationAndTransition &&
          <BrandTransition brand={brand} setBrand2={setBrand2} typeOfChart={typeOfChart} rawData={rawData} matrix={matrix} dataDuplicationAndTransition={dataDuplicationAndTransition} />
        }
      </div>
      <FloatingToast bold={'where-why'} />
    </div>
  );
};

export default observer(BrandWhereWhy);
