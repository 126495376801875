import React from 'react';
import { autorun } from 'mobx';
import Filters from '../../components/filters/filters';
import observableFiltersStore from '../../stores/filters';
import styles from './price-where-why.module.css';
import FloatingToast from '../../components/toast-for-switching-price/floating-toast';
import getMarketsMaxAndMinPrices, { MarketsMaxAndMinPricesResult } from '../../client/get-markets-max-and-min-prices';
import MarketsMaxPrices from '../../components/markets-max-prices/markets-max-prices';
import MarketsAveragePrices from '../../components/markets-average-prices/markets-average-prices';
import MarketsMinPrices from '../../components/markets-min-prices/markets-min-prices';
import MarketsPromoPrices from '../../components/markets-promo-prices/markets-promo-prices';

const PriceWhereWhy: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<MarketsMaxAndMinPricesResult>();

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const result = await getMarketsMaxAndMinPrices(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
      );

      if (result.success) {
        setData(result.data);
      }

      setIsLoading(false);
    });

    return () => dispose();
  }, []);

  return (
    <div>
      <Filters />
      <div className={styles.wrapper}>
        <div className={styles.box1}>
          <MarketsMaxPrices isLoading={isLoading} dataMaxPrice={data && data.maxPrice ? data.maxPrice : []} />
        </div>
        <div className={styles.box2}>
          <MarketsAveragePrices />
        </div>
        <div className={styles.box3}>
          <MarketsMinPrices isLoading={isLoading} dataMinPrice={data && data.minPrice ? data.minPrice : []} />
        </div>
        <div className={styles.box4}>
          <MarketsPromoPrices />
        </div>
      </div>
      <FloatingToast bold={'where-why'} />
    </div>
  );
};

export default PriceWhereWhy;
