import { getAuth } from 'firebase/auth';
import { Result } from './models';
import dateFormat from 'date-fns/format';

export interface LossAndGainResultObject {
  'Name': string;
  'Gain from market in %': number;
  'Loss to market in %': number;
  'Increased spending in %': number;
  'New buyers in %': number;
  'Decreased spending in %': number;
  'Lost buyers in %': number;
  '+/- Growth in %': number;
}

const getLossAndGainForWhoPage = async (dateFrom: Date, dateTo: Date, categoryId?: string, brandIds?: string[]): Promise<Result<LossAndGainResultObject[]>> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();

  if (!token) {
    throw new Error('Unauthorized');
  }
  
  const response = await fetch(`${process.env.REACT_APP_API}/v1/get-loss-and-gain-for-who-page?date_from=${dateFormat(dateFrom, 'yyyy-MM-dd')}&date_to=${dateFormat(dateTo, 'yyyy-MM-dd')}&category_id=${categoryId || ''}&brand_ids=${brandIds?.reduce((acc: string[], curr) => acc = [...acc, encodeURIComponent(curr)], [])?.join(',') || ''}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
  return await response.json();
};

export default getLossAndGainForWhoPage;
