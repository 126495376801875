import React from 'react';
import styles from './price-segments.module.css';
import { ResponsiveBar } from '@nivo/bar';
import { autorun } from 'mobx';
import dateFormat from 'date-fns/format';
import observableFiltersStore from '../../stores/filters';
import getPriceSegmentsForPriceWho from '../../client/get-price-segments-for-price-who';

let onChangeTimeout: NodeJS.Timeout;

interface PriceSegmentsProps {
  interval: string,
  setInterval: (interval: string) => void,
  intervalName: string,
  setIntervalName: (intervalPart: string) => void,
}

const PriceSegments: React.FC<PriceSegmentsProps> = (props: PriceSegmentsProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>();
  const [intervalStart, setIntervalStart] = React.useState<string>('0');

  const options = ['5', '10', '20', '50', '100', '200', '500', '1000'];

  const onOptionChangeHandler = (event: any) => {
    observableFiltersStore.setIntervalLength(event.target.value);
  };

  const onInputChangeHandler = (event: any) => {
    setIntervalStart(event.target.value);
    if (onChangeTimeout) {
      clearTimeout(onChangeTimeout);
    }
    onChangeTimeout = setTimeout(() => {
      observableFiltersStore.setIntervalStart(event.target.value);
    }, 1000);
  };

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const result = await getPriceSegmentsForPriceWho(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.intervalStart,
        observableFiltersStore.intervalLength,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
      );

      if (result.success) {
        setData(result.data);
      }

      setIsLoading(false);
    });

    return () => dispose();
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <div className={styles.info}>
          <h2>PRICE SEGMENTS</h2>
          <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        </div>
        <div className={styles.optionDiv}>
          <div>
            <label className={styles.intervalStartLabel}>Interval start: </label>
            <input className={styles.intervalStartInput} value={intervalStart} type="number" min="0" max="100000" onChange={onInputChangeHandler} /> <br/>
          </div>
          <div>
            <label className={styles.intervalLengthLabel}>Interval length: </label>
            <select defaultValue={'5'} onChange={onOptionChangeHandler}>
              {options.map((option, index) => <option key={index} >{option}</option>)}
            </select>
          </div>
        </div>
        <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
        {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
        <p>Source: Heco et al</p>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: data && data.colors ? data.colors[0] : 'lime'}}></div>
            <div className={styles.item}>Brands</div>
          </div>
        </div>
      </div>
      <div style={{height: '34rem'}}>
        {isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
        {!isLoading && data && data.brandNames &&
          <ResponsiveBar
            data={data.data}
            keys={data.brandNames}
            indexBy="interval"
            layout="horizontal"
            onClick={(data) => {
              props.setInterval(data['indexValue'].toString());
              props.setIntervalName(data.data.intervalName.toString());
            }}
            margin={{ top: 0, right: 20, bottom: 0, left: 65 }}
            padding={0.4}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={data.colors}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 10,
              tickPadding: 1,
              tickRotation: 0,
            }}
            axisLeft={{
              tickSize: 2,
              tickPadding: 10,
              tickRotation: 0,
            }}
            labelSkipWidth={12}
            labelSkipHeight={6}
            labelTextColor="black"
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 11,
                    fontWeight: 'bold',
                  },
                },
              },
            }}
            enableGridY={false}
            role="application"
            ariaLabel="Price Segments Bar Chart"
            barAriaLabel={function(e) {
              return e.id+': '+e.formattedValue+' in merchant: '+e.indexValue;
            }}
          />
        }
      </div>
      {props.interval && props.intervalName &&
        <div className={styles.clearChoiceButton}>
          <div>Interval: {props.interval}</div>
          <button onClick={() => {
            props.setInterval('');
            props.setIntervalName('');
          }}>
            Clear choice
          </button>
        </div>
      }
    </div>
  );
};

export default PriceSegments;
