import React from 'react';
import styles from './market-top-categories-and-brands.module.css';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import observableFiltersStore from '../../stores/filters';
import dateFormat from 'date-fns/format';
import getMarketTopCategoriesAndBrands from '../../client/get-market-top-categories-and-brands';
import { ResponsiveBar } from '@nivo/bar';

const TopCategoriesAndBrands: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [dataCategories, setDataCategories] = React.useState<any[]>([]);
  const [dataBrands, setDataBrands] = React.useState<any[]>([]);

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);
      const result = await getMarketTopCategoriesAndBrands(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
      );

      if (result.success) {
        setDataCategories(result.data.topCategories.reverse());
        setDataBrands(result.data.topBrands.reverse());
        setIsLoading(false);
      }
    });

    return () => dispose();
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2>TOP CATEGORIES</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
        <p>Source: Heco et al</p>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#00B0F0'}}></div>
            <div className={styles.item}>Value</div>
          </div>
        </div>
      </div>
      {isLoading && <div className={styles.loaderDiv}>
        <h3 className={styles.isLoading}>Loading...</h3>
      </div>}
      {!isLoading && 
        <div>
          <div style={{height: '39rem', display: 'inline-block', width: '100%'}}>
            <ResponsiveBar
              data={dataCategories}
              keys={[
                'Value',
              ]}
              indexBy="category_name"
              colors={() => '#00B0F0'}
              margin={{ top: 50, right: 10, bottom: 50, left: 140 }}
              padding={0.3}
              layout="horizontal"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 0,
                tickPadding: 50,
                tickRotation: 90,
                legend: 'Top 10 Categories',
                legendPosition: 'middle',
                legendOffset: 10,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor="white"
              role="application"
              ariaLabel="Top Categories Bar Chart"
            />
          </div>
        </div>
      }
    </div>
  );
};

export default observer(TopCategoriesAndBrands);
