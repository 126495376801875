import React from 'react';
import styles from './promotion-what.module.css';
import Filters from '../../components/filters/filters';
import PromotionLossAndGain from '../../components/promotion-loss-and-gain/promotion-loss-and-gain';
import PromotionWeeksShopperTraficIndex from '../../components/promotion-weeks-shopper-trafic-index/promotion-weeks-shopper-trafic-index';
import PromotionWeeksInitiatives from '../../components/promotion-weeks-initiatives/promotion-weeks-initiatives';
import PromotionWeeksBasketSizes from '../../components/promotion-weeks-basket-sizes/promotion-weeks-basket-sizes';
import FloatingToast from '../../components/toast-for-switching-promotion/floating-toast';

const PromotionWhat: React.FC = () => {
  return (
    <div>
      <Filters />
      <div className={styles.wrapper}>
        <div className={styles.box1}>
          <PromotionLossAndGain />
        </div>
        <div className={styles.box2}>
          <PromotionWeeksShopperTraficIndex />
        </div>
        <div className={styles.box3}>
          <PromotionWeeksInitiatives />
        </div>
        <div className={styles.box4}>
          <PromotionWeeksBasketSizes />
        </div>
      </div>
      <FloatingToast bold={'what'} />
    </div>
  );
};

export default PromotionWhat;
