import { LossAndGainLastTableColumns } from '../../containers/brand-where-why/brand-where-why';

const calculateBrandMatrix = (brandRows: Record<string, Record<string, number>>) => {

  const lastTableColumns: LossAndGainLastTableColumns = {
    P1: 0,
    P2: 0,
    Calc: 0,
    'Calc in %': 0,
    Gain: 0,
    'Gain in %': 0,
    Loss: 0,
    'Loss in %': 0,
    Increase: 0,
    'Increase in %': 0,
    Decrease: 0,
    'Decrease in %': 0,
  };

  const matrix: Record<string, LossAndGainLastTableColumns> = {};
  
  for (const brandName of Object.keys(brandRows)) {

    if (brandName === 'Increase' || brandName === 'Total') {
      continue;
    }
    
    matrix[brandName] = {...lastTableColumns};

    matrix[brandName]['P1'] = brandRows[brandName]['Total'];
    matrix[brandName]['P2'] = brandRows['Total'][brandName];

    matrix[brandName]['Calc'] = matrix[brandName]['P2'] - matrix[brandName]['P1'];
    matrix[brandName]['Gain'] = brandRows['Total'][brandName] - (brandRows['Increase'][brandName] + brandRows[brandName][brandName]);
    matrix[brandName]['Loss'] = -(brandRows[brandName]['Total'] - (brandRows[brandName]['Decrease'] + brandRows[brandName][brandName]));
    matrix[brandName]['Increase'] = brandRows['Increase'][brandName];
    matrix[brandName]['Decrease'] = -brandRows[brandName]['Decrease'];

    if (matrix[brandName]['P1'] !== 0) {
      matrix[brandName]['Calc in %'] = Math.round(100 * (matrix[brandName]['Calc'] / matrix[brandName]['P1']));
      matrix[brandName]['Gain in %'] = Math.round(100 * (matrix[brandName]['Gain'] / matrix[brandName]['P1']));
      matrix[brandName]['Loss in %'] = Math.round(100 * (matrix[brandName]['Loss'] / matrix[brandName]['P1']));
      matrix[brandName]['Increase in %'] = Math.round(100 * (matrix[brandName]['Increase'] / matrix[brandName]['P1']));
      matrix[brandName]['Decrease in %'] = Math.round(100 * (matrix[brandName]['Decrease'] / matrix[brandName]['P1']));
    } else {
      if (matrix[brandName]['Calc'] !== 0) {
        matrix[brandName]['Calc in %'] = 100;
      }
      if (matrix[brandName]['Gain'] !== 0) {
        matrix[brandName]['Gain in %'] = 100;
      }
      if (matrix[brandName]['Loss'] !== 0) {
        matrix[brandName]['Loss in %'] = 100;
      }
      if (matrix[brandName]['Increase'] !== 0) {
        matrix[brandName]['Increase in %'] = 100;
      }
      if (matrix[brandName]['Decrease'] !== 0) {
        matrix[brandName]['Decrease in %'] = 100;
      }
    }
    
  }

  return matrix;
};

export default calculateBrandMatrix;
