import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './left-menu.module.css';
import SavedSearchOutlinedIcon from '@mui/icons-material/SavedSearchOutlined';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import ControlCameraOutlinedIcon from '@mui/icons-material/ControlCameraOutlined';

interface MenuItemModel {
  label: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  path: string;
}

export const leftMenuItems: MenuItemModel[] = [
  {
    label: 'KPI',
    path: '/kpi',
    Icon: ControlCameraOutlinedIcon,
  },
  {
    label: 'Brand',
    path: '/brand-what',
    Icon: SavedSearchOutlinedIcon,
  },
  {
    label: 'Category',
    path: '/category-what',
    Icon: CategoryOutlinedIcon,
  },
  {
    label: 'Market',
    path: '/market-what',
    Icon: StoreMallDirectoryOutlinedIcon,
  },
  {
    label: 'Product',
    path: '/product-what',
    Icon: QrCodeScannerOutlinedIcon,
  },
  {
    label: 'Price',
    path: '/price-what',
    Icon: LocalOfferOutlinedIcon,
  },
  {
    label: 'Place',
    path: '/place-what',
    Icon: MapOutlinedIcon,
  },
  {
    label: 'Promotion',
    path: '/promotion-what',
    Icon: CampaignOutlinedIcon,
  },
];

const LeftMenu: React.FC = () => {
  const location = useLocation();
  
  const MenuItem: React.FC<MenuItemModel> = ({label, Icon, path}) => {
    return (
      <Link className={`${styles.item} ${location.pathname === path ? styles.active : ''}`} to={path}>
        <Icon className={styles.icon} />
        <p>{label}</p>
      </Link>
    );
  };

  return (
    <>
      <div className={styles.menu}>
        {
          leftMenuItems.map(item => <MenuItem key={item.path} label={item.label} path={item.path} Icon={item.Icon} />)
        }
      </div>
    </>
  );
};

export default LeftMenu;