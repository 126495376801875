import React from 'react';
import styles from './place-who.module.css';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import Filters from '../../components/filters/filters';
import observableFiltersStore from '../../stores/filters';
import getPlaceUserData, { PlaceUserDataModel } from '../../client/get-place-user-data';
import PlaceDistributionProfile from '../../components/place-distribution-profile/place-distribution-profile';
import Geography from '../../components/product-geography/geography';
import Age from '../../components/product-age/age';
import Consumption from '../../components/product-consumption/consumption';
import FamilyType from '../../components/product-family-type/family-type';
import FloatingToast from '../../components/toast-for-switching-place/floating-toast';

const PlaceWho: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [interval, setInterval] = React.useState<string>('');
  const [intervalName, setIntervalName] = React.useState<string>('');
  const [data, setData] = React.useState<PlaceUserDataModel>();
  
  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const result = await getPlaceUserData(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        intervalName,
      );

      if (result.success) {
        setData(result.data);
      }
      
      setIsLoading(false);
    });

    return () => dispose();
  }, [intervalName]);
  
  return (
    <div>
      <Filters />
      <div className={styles.wrapper}>
        <div className={styles.box1}>
          <PlaceDistributionProfile interval={interval} intervalName={intervalName} setInterval={setInterval} setIntervalName={setIntervalName} />
        </div>
        <div className={styles.box2}>
          <Geography isLoading={isLoading} name={'Interval'} value={interval} data={data && data.geography ? data.geography : []} />
        </div>
        <div className={styles.box3}>
          <Age isLoading={isLoading} name={'Interval'} value={interval} data={data && data.age ? data.age : []} />
        </div>
        <div className={styles.box4}>
          <Consumption isLoading={isLoading} name={'Interval'} value={interval} data={data && data.consumption ? data.consumption : []} />
        </div>
        <div className={styles.box5}>
          <FamilyType isLoading={isLoading} name={'Interval'} value={interval} data={data && data.familyType ? data.familyType : []} />
        </div>
      </div>
      <FloatingToast bold={'who'} />
    </div>
  );
};

export default observer(PlaceWho);
