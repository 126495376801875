import { LossAndGainLastTableColumns } from '../../containers/category-where-why/category-where-why';

const calculateMatrix = (merchantRows: Record<string, Record<string, number>>) => {

  const lastTableColumns: LossAndGainLastTableColumns = {
    P1: 0,
    P2: 0,
    Calc: 0,
    'Calc in %': 0,
    Gain: 0,
    'Gain in %': 0,
    Loss: 0,
    'Loss in %': 0,
    Increase: 0,
    'Increase in %': 0,
    Decrease: 0,
    'Decrease in %': 0,
  };

  const matrix: Record<string, LossAndGainLastTableColumns> = {};
  
  for (const merchantName of Object.keys(merchantRows)) {

    if (merchantName === 'Increase' || merchantName === 'Total') {
      continue;
    }
    
    matrix[merchantName] = {...lastTableColumns};

    matrix[merchantName]['P1'] = merchantRows[merchantName]['Total'];
    matrix[merchantName]['P2'] = merchantRows['Total'][merchantName];

    matrix[merchantName]['Calc'] = matrix[merchantName]['P2'] - matrix[merchantName]['P1'];
    matrix[merchantName]['Gain'] = merchantRows['Total'][merchantName] - (merchantRows['Increase'][merchantName] + merchantRows[merchantName][merchantName]);
    matrix[merchantName]['Loss'] = -(merchantRows[merchantName]['Total'] - (merchantRows[merchantName]['Decrease'] + merchantRows[merchantName][merchantName]));
    matrix[merchantName]['Increase'] = merchantRows['Increase'][merchantName];
    matrix[merchantName]['Decrease'] = -merchantRows[merchantName]['Decrease'];

    if (matrix[merchantName]['P1'] !== 0) {
      matrix[merchantName]['Calc in %'] = Math.round(100 * (matrix[merchantName]['Calc'] / matrix[merchantName]['P1']));
      matrix[merchantName]['Gain in %'] = Math.round(100 * (matrix[merchantName]['Gain'] / matrix[merchantName]['P1']));
      matrix[merchantName]['Loss in %'] = Math.round(100 * (matrix[merchantName]['Loss'] / matrix[merchantName]['P1']));
      matrix[merchantName]['Increase in %'] = Math.round(100 * (matrix[merchantName]['Increase'] / matrix[merchantName]['P1']));
      matrix[merchantName]['Decrease in %'] = Math.round(100 * (matrix[merchantName]['Decrease'] / matrix[merchantName]['P1']));
    } else {
      if (matrix[merchantName]['Calc'] !== 0) {
        matrix[merchantName]['Calc in %'] = 100;
      }
      if (matrix[merchantName]['Gain'] !== 0) {
        matrix[merchantName]['Gain in %'] = 100;
      }
      if (matrix[merchantName]['Loss'] !== 0) {
        matrix[merchantName]['Loss in %'] = 100;
      }
      if (matrix[merchantName]['Increase'] !== 0) {
        matrix[merchantName]['Increase in %'] = 100;
      }
      if (matrix[merchantName]['Decrease'] !== 0) {
        matrix[merchantName]['Decrease in %'] = 100;
      }
    }
    
  }

  return matrix;
};

export default calculateMatrix;
