interface ConsumptionPartModel {
  one_per_week: number,
  one_per_2weeks: number,
  one_per_30days: number,
  one_per_90days: number,
  one_per_180days: number,
  one_per_360days: number,
}

interface ConsumptionModel {
  categorization: ConsumptionPartModel,
  values: ConsumptionPartModel,
}

const formatDataForConsumptionChart = (data: ConsumptionModel) => {

  const formattedData: any[] = [];

  formattedData.push({
    'id': '% of buyers',
    '1 pr. week': data.categorization.one_per_week,
    '1 pr. 2 weeks': data.categorization.one_per_2weeks,
    '1 pr. 30 days': data.categorization.one_per_30days,
    '1 pr. 90 days': data.categorization.one_per_90days,
    '1 pr. 180 days': data.categorization.one_per_180days,
    '1 pr. 360 days': data.categorization.one_per_360days,
  });

  formattedData.push({
    'id': '% of value',
    '1 pr. week': data.values.one_per_week,
    '1 pr. 2 weeks': data.values.one_per_2weeks,
    '1 pr. 30 days': data.values.one_per_30days,
    '1 pr. 90 days': data.values.one_per_90days,
    '1 pr. 180 days': data.values.one_per_180days,
    '1 pr. 360 days': data.values.one_per_360days,
  });

  return formattedData;
};

export default formatDataForConsumptionChart;
