import { getAuth } from 'firebase/auth';
import {Result} from './models';
import dateFormat from 'date-fns/format';

interface PriceDataObject {
  x: string,
  y: number,
}

interface PriceResult {
  id: string,
  data: PriceDataObject[],
}

export interface MarketsPromotionPricesResult {
  promotionPrice: PriceResult[];
}

const getMarketsPromotionPrices = async (dateFrom: Date, dateTo: Date, categoryId?: string, merchantIds?: string[], brandIds?: string[]): Promise<Result<MarketsPromotionPricesResult>> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();

  if (!token) {
    throw new Error('Unauthorized');
  }
  
  const response = await fetch(`${process.env.REACT_APP_API}/v1/get-markets-promotion-prices?date_from=${dateFormat(dateFrom, 'yyyy-MM-dd')}&date_to=${dateFormat(dateTo, 'yyyy-MM-dd')}&category_id=${categoryId || ''}&brand_ids=${brandIds?.reduce((acc: string[], curr) => acc = [...acc, encodeURIComponent(curr)], [])?.join(',') || ''}&merchant_ids=${merchantIds?.reduce((acc: string[], curr) => acc = [...acc, encodeURIComponent(curr)], [])?.join(',') || ''}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
  return await response.json();
};

export default getMarketsPromotionPrices;
