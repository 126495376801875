import React from 'react';
import styles from './promotion-loss-and-gain.module.css';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import dateFormat from 'date-fns/format';
import observableFiltersStore from '../../stores/filters';
import getPromotionLossAndGain from '../../client/get-promotion-loss-and-gain';
import { ResponsiveBar } from '@nivo/bar';

const PromotionLossAndGain: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any[]>();

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const resultPromotionLossAndGain = await getPromotionLossAndGain(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
      );

      if (resultPromotionLossAndGain.success) {
        setData(resultPromotionLossAndGain.data);
      }

      setIsLoading(false);
    });

    return () => dispose();
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2>GAIN {`&`} LOSS PROMO</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
        {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
        {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
        <p>Source: Heco et al</p>
        <div className={styles.legend} style={{top: observableFiltersStore.brands && observableFiltersStore.brands.length > 4  ? '-0.25rem' : '0.5rem'}}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#FFC62F'}}></div>
            <div className={styles.item}>Loss to market in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#0096FF'}}></div>
            <div className={styles.item}>Gain from market in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#FF9D2C'}}></div>
            <div className={styles.item}>Decreased spending in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#21B8FE'}}></div>
            <div className={styles.item}>Increased spending in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#FE872B'}}></div>
            <div className={styles.item}>Lost buyers in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#A3E2FF'}}></div>
            <div className={styles.item}>New buyers in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: 'white'}}></div>
            <div className={styles.item}>+/- Growth in %</div>
          </div>
        </div>
      </div>
      <div className={styles.graph}>
        {isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
        {!isLoading && data && 
          <ResponsiveBar
            data={data}
            indexBy="Week"
            keys={[
              '+/- Growth in %',
              'Gain from market in %', 
              'Increased spending in %', 
              'New buyers in %', 
              'Lost buyers in %', 
              'Decreased spending in %', 
              'Loss to market in %', 
            ].reverse()}
            groupMode="stacked"
            layout="vertical"
            padding={0.4}
            margin={{ top: 20, right: 30, bottom: 30, left: 30 }}
            colors={['white', '#0096FF', '#21B8FE', '#A3E2FF', '#FE872B', '#FF9D2C', '#FFC62F'].reverse()}
            indexScale={{ type: 'band', round: true }}  
            enableGridX={false}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            axisLeft={{
              tickSize: 2,
              tickPadding: 5,
              tickRotation: 0,
            }}
            labelSkipWidth={12}
            labelSkipHeight={0}
            labelTextColor="black"
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: data.length > 20 ? 7 : data.length > 15 ? 9 : 11,
                    fontWeight: 'bold',
                  },
                },
              },
            }}
            ariaLabel="Promotion Loss and Gain Stacked Bar Chart"
          />
        }
      </div>
    </div>
  );
};

export default observer(PromotionLossAndGain);
