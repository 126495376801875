import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import observableUiStore from '../../stores/ui';
import Header from '../header/header';

const ProtectedLayout: React.FC<{alt?: boolean}> = props => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>();

  React.useEffect(() => {
    observableUiStore.setIsLoading(true);
    const auth = getAuth();
    onAuthStateChanged(auth, user => {
      observableUiStore.setIsLoading(false);
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });
  }, []);

  if (isAuthenticated !== false) {
    return (
      <>
        <Header />
        {isAuthenticated ? <Outlet /> : null}
      </>
    );
  } else {
    return <Navigate to="/auth/login" replace={true} />;
  }
};

export default ProtectedLayout;