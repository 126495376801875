import { LossAndGainLastTableColumns } from '../../../containers/brand-where-why/brand-where-why';

const brandLossToMarket = (brandLosing: string, brandRows: Record<string, Record<string, number>>, matrix: Record<string, LossAndGainLastTableColumns>) => {

  const brandLossToMarket: Record<string, number> = {};
  let sumLossToMarket = 0;

  for (const brandName of Object.keys(brandRows)) {

    if (brandName === 'Increase' || brandName === 'Total') continue;
    
    if (brandName === brandLosing) {
      brandLossToMarket[brandName] = matrix[brandName]['Loss in %'];
      continue;
    }

    brandLossToMarket[brandName] = -Math.round(10000 * (brandRows[brandLosing][brandName] / matrix[brandLosing]['P1'])) / 100;
    sumLossToMarket += -brandLossToMarket[brandName];
  
  }

  return {
    'brandLossToMarket': brandLossToMarket,
    'sumLossToMarket': Math.round(100 * sumLossToMarket) / 100,
  };
};

export default brandLossToMarket;
