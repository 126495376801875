import React from 'react';
import styles from './basket-size.module.css';
import { observer } from 'mobx-react-lite';
import observableFiltersStore from '../../stores/filters';
import dateFormat from 'date-fns/format';
import { ResponsiveBar } from '@nivo/bar';

interface BasketModel {
  name: string;
  average_value: number;
}

interface BasketSizeProps {
  isLoading: boolean;
  data: BasketModel[];
  value?: string;
  name: string;
  isMarketWho?: boolean;
}

const BasketSize: React.FC<BasketSizeProps> = (props: BasketSizeProps) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2>BASKET SIZE</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        {!props.isMarketWho && <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>}
        {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
        {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
        {props.value ? <p>{props.name}: {props.value}</p> : ''}
        <p>Source: Heco et al</p>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#00B0F0'}}></div>
            <div className={styles.item}>Average</div>
          </div>
        </div>
      </div>
      <div className={styles.graph}>
        {props.isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
        {!props.isLoading && props.data && 
          <ResponsiveBar
            data={props.data.reduce((acc: any[], curr) => acc = [...acc, { id: curr.name, Average: curr.average_value}], [])}
            keys={[
              'Average',
            ]}
            colors={() => '#00B0F0'}
            indexBy="id"
            layout="vertical"
            ariaLabel=""
            label={item => {
              return item.data.Average;
            }}
            padding={0.2}
            enableGridX={false}
            enableGridY={false}
            margin={
              {
                bottom: 47,
                top: 40,
              }
            }
          />
        }
      </div>
    </div>
  );
};

export default observer(BasketSize);
