import React from 'react';
import Filters from '../../components/filters/filters';
import { autorun } from 'mobx';
import observableFiltersStore from '../../stores/filters';
import styles from './product-where-why.module.css';
import FloatingToast from '../../components/toast-for-switching-product/floating-toast';
import getProductWhereWhy, { ProductWhereWhyResult } from '../../client/get-product-where-why';
import ProductTrial from '../../components/product-trial/product-trial';
import ProductRBR from '../../components/product-RBR/product-RBR';
import ProductBuyingIndex from '../../components/product-buying-index/product-buying-index';
import ProductPredictedShare from '../../components/product-predicted-share/product-predicted-share';

const ProductWhereWhy: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<ProductWhereWhyResult>();

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const result = await getProductWhereWhy(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
        observableFiltersStore.monthEquivalent,
      );

      if (result.success) {
        setData(result.data);
      }

      setIsLoading(false);
    });

    return () => dispose();
  }, []);

  const options = ['5', '7', '10', '15', '20', '30', '50', '100'];

  const onOptionChangeHandler = (event: any) => {
    observableFiltersStore.setMonthEquivalent(event.target.value);
  };

  return (
    <div>
      <Filters />
      <div className={styles.monthEquivalent}>
        <label className={styles.monthEquivalentLabel}>Month equivalent: </label>
        <select defaultValue={'30'} onChange={onOptionChangeHandler}>
          {options.map((option, index) => <option key={index} >{option}</option>)}
        </select>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.box1}>
          <ProductTrial isLoading={isLoading} data={data && data.trial ? data.trial : []} />
        </div>
        <div className={styles.box2}>
          <ProductRBR isLoading={isLoading} data={data && data.RBR ? data.RBR : []} />
        </div>
        <div className={styles.box3}>
          <ProductBuyingIndex isLoading={isLoading} data={data && data.buyingIndex ? data.buyingIndex : []} />
        </div>
        <div className={styles.box4}>
          <ProductPredictedShare isLoading={isLoading} data={data && data.predictedShare ? data.predictedShare : []} />
        </div>
      </div>
      <FloatingToast bold={'where-why'} />
    </div>
  );
};

export default ProductWhereWhy;
