import React from 'react';
import styles from './brand-transition.module.css';
import dateFormat from 'date-fns/format';
import observableFiltersStore from '../../stores/filters';
import { LossAndGainLastTableColumns, BrandDuplicationAndTransition } from '../../containers/brand-where-why/brand-where-why';

import brandGainFromMarket from './extract-data/brandGainFromMarket';
import brandLossToMarket from './extract-data/brandLossToMarket';
import brandGainedToCategory from './extract-data/brandGainedToCategory';
import brandLostFromCategory from './extract-data/brandLostFromCategory';

import formatDataForBrandGainFromMarket from './format-data/formatDataForBrandGainFromMarket';
import formatDataForBrandLossToMarket from './format-data/formatDataForBrandLossToMarket';

import BrandGainFromMarketChart from './watterfall-charts/BrandGainFromMarketChart';
import BrandLossToMarketChart from './watterfall-charts/BrandLossToMarketChart';
import BrandGainedToCategoryChart from './watterfall-charts/BrandGainedToCategoryChart';
import BrandLostFromCategoryChart from './watterfall-charts/BrandLostFromCategoryChart';

import brandSharing from './extract-data/brandSharing';
import brandOnlyAt from './extract-data/brandOnlyAt';
import brandNotInTheShop from './extract-data/brandNotInTheShop';

import formatDataForBrandSharing from './format-data/formatDataForBrandSharing';

import BrandOnlyAtChart from './watterfall-charts/BrandOnlyAtChart';
import BrandNotInTheShopChart from './watterfall-charts/BrandNotInTheShopChart';
import BrandSharingChart from './watterfall-charts/BrandSharingChart';

interface BrandTransitionProps {
  brand?: string,
  typeOfChart?: string,
  rawData: Record<string, Record<string, number>>,
  matrix: Record<string, LossAndGainLastTableColumns>,
  dataDuplicationAndTransition: BrandDuplicationAndTransition,
  setBrand2: (brand: string) => void,
}

const BrandTransition: React.FC<BrandTransitionProps> = (props: BrandTransitionProps) => {
  return (
    <div className={styles.middlesChild}>
      {!props.typeOfChart && (
        <div className={styles.transitionInfo}>
          <h2>TRANSITIION</h2>
          <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
          <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
          {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
          {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
          <p className={styles.transitionInfo}>Source: Heco et al</p>
          <p> 
            Deep dive the transition i.e. where do shoppers buy by choosing a retail chain and a metric in the Loss {'&'} Gain or Duplication graph
          </p>
        </div>
      )}
      {props.typeOfChart &&
        <div>
          <p><span className={styles.h1Header}>TRANSITIION</span>, {props.brand + ' ' + props.typeOfChart}</p>
          <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
          <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
          {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
          {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
          <p>Source: Heco et al</p>
        </div>
      }
      {props.typeOfChart === 'Gain from market' && props.brand && (
        <div style={{'height': '11rem'}}>
          <BrandGainFromMarketChart brand={props.brand} setBrand2={props.setBrand2} data={formatDataForBrandGainFromMarket(props.brand, brandGainFromMarket(props.brand, props.rawData, props.matrix))} />
        </div>
      )}
      {props.typeOfChart === 'Loss to market' && props.brand && (
        <div style={{'height': '11rem'}}>
          <BrandLossToMarketChart brand={props.brand} setBrand2={props.setBrand2} data={formatDataForBrandLossToMarket(props.brand, brandLossToMarket(props.brand, props.rawData, props.matrix))} />
        </div>
      )}
      {props.typeOfChart === 'Gained to category' && props.brand && (
        <div style={{'height': '11rem'}}>
          <BrandGainedToCategoryChart data={brandGainedToCategory(props.brand, props.matrix)} />
        </div>
      )}
      {props.typeOfChart === 'Lost from category' && props.brand && (
        <div style={{'height': '11rem'}}>
          <BrandLostFromCategoryChart data={brandLostFromCategory(props.brand, props.matrix)} />
        </div>
      )}
      {props.typeOfChart === 'Sharing' && props.brand && (
        <div style={{'height': '11rem'}}>
          <BrandSharingChart brand={props.brand} setBrand2={props.setBrand2} data={formatDataForBrandSharing(props.brand, brandSharing(props.brand, props.dataDuplicationAndTransition.brandsSharing))} />
        </div>
      )}
      {props.typeOfChart === 'Only at' && props.brand && (
        <div style={{'height': '11rem'}}>
          <BrandOnlyAtChart data={brandOnlyAt(props.brand, props.dataDuplicationAndTransition.dataDuplication)} />
        </div>
      )}
      {props.typeOfChart === 'Not in the shop' && props.brand && (
        <div style={{'height': '11rem'}}>
          <BrandNotInTheShopChart data={brandNotInTheShop(props.brand, props.dataDuplicationAndTransition.dataDuplication)} />
        </div>
      )}
    </div>
  );
};

export default BrandTransition;
