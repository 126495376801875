import React from 'react';
import styles from './product-predicted-share.module.css';
import dateFormat from 'date-fns/format';
import { ResponsiveBar } from '@nivo/bar';
import observableFiltersStore from '../../stores/filters';

const ProductPredictedShare: React.FC<any> = (props: any) => {

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2>PREDICTED SHARE (TRB MODEL)</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
        {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
        {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
        <p>Source: Heco et al</p>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#24B8FD'}}></div>
            <div className={styles.item}>Brands</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: 'red'}}></div>
            <div className={styles.item}>Category</div>
          </div>
        </div>
      </div>
      <div className={styles.graph}>
        {props.isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
        {!props.isLoading && props.data && 
          <ResponsiveBar
            data={props.data}
            keys={['Brands', 'Category']}
            colors={['#24B8FD', 'red']}
            indexBy="month"
            margin={{ top: 50, right: 25, bottom: 50, left: 50 }}
            padding={0.3}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Months',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            labelSkipWidth={12}
            labelSkipHeight={2}
            role="application"
            ariaLabel="Predicted Share Grouped Bar Chart"
          />
        }
      </div>
    </div>
  );
};

export default ProductPredictedShare;
