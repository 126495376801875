import React from 'react';
import styles from './market-share.module.css';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import observableFiltersStore from '../../stores/filters';
import dateFormat from 'date-fns/format';
import { ResponsiveBar } from '@nivo/bar';
import getMarketShareData, { MarketShareModel } from '../../client/get-market-share';


const MarketShare: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<MarketShareModel[]>([]);
  const [customData, setCustomData] = React.useState<MarketShareModel[]>([]);

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);
      const result = await getMarketShareData(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
      );

      if (result.success) {
        setData(result.data);
        setIsLoading(false);
      }
    });

    return () => dispose();
  }, []);

  const sumTotal = (data: MarketShareModel[]) => {
    const totalCurrent = data.reduce((total: number, currentValue: MarketShareModel) => total + currentValue.current_value, 0);
    const totalPrevious = data.reduce((total: number, currentValue: MarketShareModel) => total + currentValue.last_year_value, 0);
    const newData = [
      {
        id: 'Total', current_value: Math.round(100 * totalCurrent) / 100, last_year_value: Math.round(100 * totalPrevious) / 100,
      },
    ];
    return newData;
  };
  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2>TOP 10 MARKET SHARE</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        <p>Source: Heco et al</p>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#0096FF'}}></div>
            <div className={styles.item}>Current period</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#B2BEB5'}}></div>
            <div className={styles.item}>Last period</div>
          </div>
        </div>
      </div>

      <div className={styles.graphsContainer}>
        <div className={styles.graph}>
          {isLoading || !data ?
            <h3 className={styles.isLoading}>Loading...</h3> :
            <ResponsiveBar
              data={data.reduce((acc: any[], curr) => acc = [...acc, { name: curr.brand_name, 'Current period': curr.current_value, 'Last period': curr.last_year_value }], [])}
              keys={[
                'Current period',
                'Last period',
              ]}
              colors={['#0096FF', '#B2BEB5']}
              colorBy="id"
              indexBy="name"
              layout="vertical"
              indexScale={{ type: 'band', round: false }}
              tooltip={() => null}
              padding={0.2}
              enableGridX={false}
              enableGridY={false}
              groupMode="grouped"
              onClick={(item, event) => {
                if (data) {
                  // this assures only the current value graph bar is interactive
                  if (item.id === 'Current period') {
                    // If item does not already exist on the customData array
                    // add it
                    if (!customData.some(i => i.brand_name === item.indexValue)) {
                      const obj = data.find(i => i.brand_name === item.indexValue);
                      obj && setCustomData(previous => [...previous, obj]);
                      event.currentTarget.style.fill = '#FFFF00';
                      // Else remove it
                    } else {
                      setCustomData(previous => previous.filter(data => data.brand_name !== item.indexValue));
                      event.currentTarget.style.fill = '';
                    }
                  }
                }
              }}
              margin={
                {
                  bottom: 47,
                  top: 40,
                }
              }
            />
          }
        </div>
        <div className={styles.graphTotal}>
          {isLoading || !data ?
            <h3>Loading...</h3> :
            <ResponsiveBar
              data={customData.length === 0 ? sumTotal(data).reverse() : sumTotal(customData).reverse()}
              keys={[
                'current_value',
                'last_year_value',
              ]}
              colors={['#0096FF', '#B2BEB5']}
              colorBy="id"
              indexBy="id"
              layout="vertical"
              ariaLabel=""
              tooltip={() => null}
              padding={0.1}
              enableGridX={false}
              enableGridY={false}
              groupMode="grouped"
              margin={
                {
                  bottom: 47,
                  top: 40,
                }
              }
            />
          }
        </div>
      </div>
    </div>
  );
};

export default observer(MarketShare);
