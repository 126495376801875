import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import Logo from '../../components/logo/logo';
import styles from './sign-up.module.css';
import Arrow from '../../components/arrow/arrow';
import Alert from '@mui/material/Alert';
import { useNavigate, useParams } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import createUser from '../../client/create-user';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import observableUiStore from '../../stores/ui';

const SignUp: React.FC = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [subscribe, setSubscribe] = useState<boolean>(false);
  const { key } = useParams();
  const navigate = useNavigate();

  const createAccount = async () => {
    if (!key) return setError('Missing activation key.');
    if (!name) return setError('Name is a required field.');
    if (!email) return setError('Email is a required field.');
    if (!password) return setError('Password is a required field.');
    if (password !== repeatPassword) return setError('Passwords do not match.');
    if (!password.match('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')) {
      return setError('Password must include at least one uppercase letter, one lowercase letter, one number, and be at least 8 characters long.');
    }
    
    setError('');
    observableUiStore.setIsLoading(true);
    try {
      const result = await createUser(name, email, password, key, subscribe);
      if (result.success === true) {
        const auth = getAuth();
        await signInWithEmailAndPassword(auth, email, password);
        observableUiStore.setIsLoading(false);
        navigate('/', {replace: true});
      } else {
        observableUiStore.setIsLoading(false);
        setError(result.error || 'An unexpected error has occurred. Please try again.');
      }
    } catch (e) {
      observableUiStore.setIsLoading(false);
      setError(e.message || 'An unexpected error has occurred. Please try again.');
    }
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <Logo className={styles.logo} />
        
        <TextField className={styles.input} variant="outlined" value={name} type="text" label="Name" onChange={e => setName(e.target.value)} fullWidth />
        <TextField className={styles.input} variant="outlined" value={email} type="email" label="Work email" onChange={e => setEmail(e.target.value)} fullWidth />
        <TextField className={styles.input} variant="outlined" value={password} type="password" label="Password" onChange={e => setPassword(e.target.value)} fullWidth />
        <TextField className={styles.input} variant="outlined" value={repeatPassword} type="password" label="Repeat password" onChange={e => setRepeatPassword(e.target.value)} fullWidth />
        <FormControlLabel className={styles.checkbox} control={<Checkbox checked={subscribe} onChange={e => setSubscribe(e.target.checked)} />} label="Subscribe to newsletters" />
        
        <Button className={styles.button} fullWidth variant="contained" color="secondary" startIcon={<Arrow color="primary" />} onClick={createAccount}>Sign up</Button>
        
        <a className={styles.contact} href="https://hecoetal.com/contact">Don't have an account? Get in touch!</a>
        {error ? <Alert severity="error" className={styles.alert}>{error}</Alert> : null}
      </div>
    </div>
  );
};

export default SignUp;