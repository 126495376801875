import { LossAndGainLastTableColumns } from '../../../containers/brand-where-why/brand-where-why';

const brandGainFromMarket = (brandGaining: string, brandRows: Record<string, Record<string, number>>, matrix: Record<string, LossAndGainLastTableColumns>) => {

  const brandGainFromMarket: Record<string, number> = {};
  let sumGainFromMarket = 0;

  for (const brandName of Object.keys(brandRows)) {

    if (brandName === 'Increase' || brandName === 'Total') continue;
    
    if (brandName === brandGaining) {
      brandGainFromMarket[brandName] = matrix[brandName]['Gain in %'];
      continue;
    }

    if (matrix[brandGaining]['P1'] !== 0) {
      brandGainFromMarket[brandName] = Math.round(10000 * (brandRows[brandName][brandGaining] / matrix[brandGaining]['P1'])) / 100;
    } else { // cannot perform the standard distribution of gain (loss) because of p1 equaling 0
      brandGainFromMarket[brandName] = 0;
      sumGainFromMarket = 100;
    }

    sumGainFromMarket += brandGainFromMarket[brandName];

  }

  return {
    'brandGainFromMarket': brandGainFromMarket,
    'sumGainFromMarket': Math.round(100 * sumGainFromMarket) / 100,
  };
};

export default brandGainFromMarket;
