import { LossAndGainLastTableColumns } from '../../../containers/category-where-why/category-where-why';

const merchantGainedToCategory = (merchant: string, matrix: Record<string, LossAndGainLastTableColumns>) => {
  
  return [{
    'Merchant': merchant,
    'Gained': matrix[merchant]['Increase in %'],
    'Lost': 0,
  }];
};

export default merchantGainedToCategory;
