import { getAuth } from 'firebase/auth';
import {Result} from './models';
import dateFormat from 'date-fns/format';

export interface MarketShareModel {
  merchant_id: string;
  last_year_value: number;
  current_value: number;
}

const getMarketMarketShareData = async (dateFrom: Date, dateTo: Date, merchantIds?: string[]): Promise<Result<MarketShareModel[]>> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();

  if (!token) {
    throw new Error('Unauthorized');
  }
  
  const response = await fetch(`${process.env.REACT_APP_API}/v1/get-market-market-share?date_from=${dateFormat(dateFrom, 'yyyy-MM-dd')}&date_to=${dateFormat(dateTo, 'yyyy-MM-dd')}&merchant_ids=${merchantIds?.reduce((acc: string[], curr) => acc = [...acc, encodeURIComponent(curr)], [])?.join(',') || ''}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
  return await response.json();
};

export default getMarketMarketShareData;
