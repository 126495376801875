import { BrandDuplicationInterface } from '../../../containers/brand-where-why/brand-where-why';

const brandNotInTheShop = (brand: string, dataDuplication: BrandDuplicationInterface[]) => {
  
  const index = dataDuplication.findIndex(brandObject => brandObject['Brand'] === brand);

  return [{
    'Brand': brand,
    'Not in the shop': dataDuplication[index]['Not in the shop'],
  }];
};

export default brandNotInTheShop;
