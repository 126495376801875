import { ThemeProvider } from '@emotion/react';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './containers/login/login';
import SignUp from './containers/sign-up/sign-up';
import theme from './theme';
import { initializeApp } from 'firebase/app';
import config from './config';
import DashboardLayout from './components/dashboard-layout/dashboard-layout';
import Home from './containers/home/home';
import ForgotPassword from './containers/forgot-password/forgot-password';
import ForgotPasswordUserIdCode from './containers/forgot-password-userid-code/forgot-password-userid-code';
import Loader from './components/loader/loader';
import BrandWhat from './containers/brand-what/brand-what';
import BrandWhereWhy from './containers/brand-where-why/brand-where-why';
import CategoryWhat from './containers/category-what/category-what';
import CategoryWhereWhy from './containers/category-where-why/category-where-why';
import ProtectedLayout from './components/protected-layout/protected-layout';
import CategoryWho from './containers/category-who/category-who';
import BrandWho from './containers/brand-who/brand-who';
import MarketWhat from './containers/market-what/market-what';
import MarketWhereWhy from './containers/market-where-why/market-where-why';
import PriceWhat from './containers/price-what/price-what';
import PriceWhereWhy from './containers/price-where-why/price-where-why';
import ProductWhat from './containers/product-what/product-what';
import ProductWhereWhy from './containers/product-where-why/product-where-why';
import ProductWho from './containers/product-who/product-who';
import PriceWho from './containers/price-who/price-who';
import PlaceWhat from './containers/place-what/place-what';
import PlaceWhereWhy from './containers/place-where-why/place-where-why';
import PlaceWho from './containers/place-who/place-who';
import PromotionWhat from './containers/promotion-what/promotion-what';
import PromotionWhereWhy from './containers/promotion-where-why/promotion-where-why';
import PromotionWho from './containers/promotion-who/promotion-who';
import MarketWho from './containers/market-who/market-who';

initializeApp(config.firebaseConfig);

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<ProtectedLayout />}>
          <Route index element={<Home />} />
          <Route path="/" element={<DashboardLayout />}>
            <Route path="brand-what" element={<BrandWhat />} />
            <Route path="brand-where-why" element={<BrandWhereWhy />} />
            <Route path="brand-who" element={<BrandWho />} />
            <Route path="category-what" element={<CategoryWhat />} />
            <Route path="category-where-why" element={<CategoryWhereWhy />} />
            <Route path="category-who" element={<CategoryWho />} />
            <Route path="market-what" element={<MarketWhat />} />
            <Route path="market-where-why" element={<MarketWhereWhy />} />
            <Route path="price-what" element={<PriceWhat />} />
            <Route path="price-where-why" element={<PriceWhereWhy />} />
            <Route path="product-what" element={<ProductWhat />} />
            <Route path="product-where-why" element={<ProductWhereWhy />} />
            <Route path="product-who" element={<ProductWho />} />
            <Route path="price-who" element={<PriceWho />} />
            <Route path="place-what" element={<PlaceWhat />} />
            <Route path="place-where-why" element={<PlaceWhereWhy />} />
            <Route path="place-who" element={<PlaceWho />} />
            <Route path="promotion-what" element={<PromotionWhat />} />
            <Route path="promotion-where-why" element={<PromotionWhereWhy />} />
            <Route path="promotion-who" element={<PromotionWho />} />
            <Route path="market-who" element={<MarketWho />} />
          </Route>
        </Route>
        <Route path="auth">
          <Route path="login" element={<Login />} />
          <Route path="sign-up/:key" element={<SignUp />} />
          <Route path="forgot-password/" element={<ForgotPassword />} />
          <Route path="forgot-password/:email/:userId/:code" element={<ForgotPasswordUserIdCode />} />
        </Route>
      </Routes>
      <Loader />
    </ThemeProvider>
  );
};

export default App;
