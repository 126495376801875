import React from 'react';
import styles from './market-penetration-flow.module.css';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import observableFiltersStore from '../../stores/filters';
import dateFormat from 'date-fns/format';
import getMarketPenetrationFlow, { MarketPenetrationFlowResult } from '../../client/get-market-penetration-flow';
import { ResponsiveSankey } from '@nivo/sankey';

const PenetrationFlow: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<MarketPenetrationFlowResult>();

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);
      const result = await getMarketPenetrationFlow(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
      );

      if (result.success) {
        setData(result.data);
        setIsLoading(false);
      }
    });

    return () => dispose();
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2>RETAIL TRANSITION</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        <p>Source: Heco et al</p>
      </div>
      {isLoading && <div className={styles.loaderDiv}>
        <h3 className={styles.isLoading}>Loading...</h3>
      </div>}
      {!isLoading && data && data.nodes.length > 0 && 
        <div>
          <div className={styles.marginTop}>
            <div className={styles.p1}>Last period</div>
            <div className={styles.p2}>Current period</div>
          </div>
          <div style={{height: '36rem'}}> 
            <ResponsiveSankey
              data={data}
              margin={{ top: 20, right: 100, bottom: 20, left: 100 }}
              align="center"
              colors={{ scheme: 'paired' }}
              nodeOpacity={1}
              nodeHoverOthersOpacity={0.5}
              nodeThickness={15}
              nodeSpacing={25}
              nodeBorderWidth={0}
              nodeBorderColor={{ theme: 'background' }}
              nodeBorderRadius={1}
              linkOpacity={0.5}
              linkHoverOthersOpacity={0.1}
              linkContract={3}
              enableLinkGradient={true}
              labelPosition="outside"
              labelPadding={5}
              label={brand => brand.name}
              linkTooltip={(node: any) => {
                return (
                  <span style={{background: 'white', color: 'black'}}>
                    {node.link.valueP1} {'->'} {node.link.source.label} - {node.link.target.label} {'->'} {node.link.value}
                  </span>
                );
              }}
              labelTextColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    1,
                  ],
                ],
              }}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default observer(PenetrationFlow);
