import React from 'react';
import styles from './category-segmentation.module.css';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import observableFiltersStore from '../../stores/filters';
import dateFormat from 'date-fns/format';
import getCategorySegmentation from '../../client/get-category-segmentation';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import formatDataForCategorySegmentation from './formatDataForCategorySegmentation';
import calculateAverage from './calculateAverage';

const CategorySegmentation: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any[]>([]);
  const [categoryName, setCategoryName] = React.useState<string | undefined>('');


  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);
      const result = await getCategorySegmentation(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
      );

      if (result.success) {
        if (observableFiltersStore.defaultCategory?.name) {
          setCategoryName(observableFiltersStore.defaultCategory?.name);
        }
        setData(formatDataForCategorySegmentation(result.data));
        setIsLoading(false);
      }
    });

    return () => dispose();
  }, []);

  React.useEffect(() => {
    setCategoryName(observableFiltersStore.optiusCategory?.name);
  }, [observableFiltersStore.optiusCategory?.name]);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2>CATEGORY SEGMENTATION</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        <p>Source: Heco et al</p>
      </div>

      <div className={styles.graph}>
        {isLoading || !data ?
          <h3 className={styles.isLoading}>Loading...</h3> :
          <ResponsiveScatterPlot
            data={data}
            nodeSize={10}
            theme={{
              background: '#eee',
              textColor: 'black',
            }}
            markers={[
              {
                axis: 'y',
                value: calculateAverage('y', data),
                lineStyle: { stroke: 'white', strokeWidth: 3 },
                legend: 'avg. pen.',
                legendOrientation: 'horizontal',
              },
              {
                axis: 'x',
                value: calculateAverage('x', data),
                lineStyle: { stroke: 'white', strokeWidth: 3 },
                legend: 'avg. freq.',
                legendOrientation: 'horizontal',
              },
            ]}
            margin={{ top: 50, right: 50, bottom: 75, left: 75 }}
            xScale={{ type: 'linear', min: 0, max: 'auto' }}
            xFormat=">-.2f"
            yScale={{ type: 'linear', min: 0, max: 'auto' }}
            yFormat=">-.2f"
            blendMode="multiply"
            axisTop={null}
            axisRight={null}
            colors={(node: any) => {
              return node.serieId !== categoryName ? '#B2BEB5' : '#0096FF';
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Penetration',
              legendPosition: 'middle',
              legendOffset: 46,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Frequency',
              legendPosition: 'middle',
              legendOffset: -60,
            }}
          />
        }
      </div>
    </div>
  );
};

export default observer(CategorySegmentation);
