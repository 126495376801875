import React from 'react';
import Filters from '../../components/filters/filters';
import styles from './brand-what.module.css';
import MarketShare from '../../components/market-share/market-share';
import ValueDriversPen from '../../components/value-drivers-pen/value-drivers-pen';
import ValueDriversPrice from '../../components/value-drivers-price/value-drivers-price';
import PenetrationFlow from '../../components/penetration-flow/penetration-flow';
import FloatingToast from '../../components/toast-for-switching-brand/floating-toast';

const BrandWhat: React.FC = () => {
  return (
    <div className={styles.wrap}>
      <Filters />
      <div style={{height: '40rem'}} className={styles.content}>
        <div className={styles.left}>
          <MarketShare />
          <ValueDriversPen /> 
          <ValueDriversPrice />
        </div>
        <div className={styles.right}>
          <PenetrationFlow height="42rem" />
        </div>
      </div>
      <FloatingToast bold={'what'} />
    </div>
  );
};

export default BrandWhat;
