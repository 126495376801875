interface MerchantGainMarket {
  'Merchant': string,
  'Gained': number,
  'Lost': number,
  'Whitespace': number,
}

interface MerchantGainData{
  merchantGainFromMarket: Record<string, number>,
  sumGainFromMarket: number,
}

const formatDataForMerchantGainFromMarket = (merchant: string, merchantGainData: MerchantGainData) => {
  
  const formatedData: [string, number][] = Object.entries(merchantGainData.merchantGainFromMarket);
  const sumGainFromMarket = merchantGainData.sumGainFromMarket;
  let whiteSpaceLeft = sumGainFromMarket;

  formatedData.sort((array1: [string, number], array2: [string, number]) => { // Array of arrays, where the inner arrays contain only 2 elements, 1st a string, 2nd a number 
    if (array1[1] > array2[1]) {
      return -1;
    }
    if (array1[1] < array2[1]) {
      return 1;
    }
    return 0;
  });

  const index = formatedData.findIndex(array => array[0] === merchant);
  const merchantGaining = formatedData[index];
  formatedData.splice(index, 1);
  formatedData.splice(0, 0, merchantGaining);
  
  const data: MerchantGainMarket[] = [];
  for (let i = 0; i < formatedData.length; i++) {
    if (formatedData[i][0] === merchant) {
      data.push({
        'Merchant': merchant,
        'Gained': sumGainFromMarket,
        'Lost': 0,
        'Whitespace': 0,
      });
      continue;
    };
    if (formatedData[i][1] === 0) break;
    const objectForMerchant: MerchantGainMarket = {
      'Merchant': formatedData[i][0],
      'Gained': 0,
      'Lost': Number(formatedData[i][1]),
      'Whitespace': whiteSpaceLeft > 0 ? (whiteSpaceLeft - Number(formatedData[i][1])) : 0,
    };
    data.push(objectForMerchant);
    whiteSpaceLeft = data[data.length - 1]['Whitespace'];
  };

  return data;
};

export default formatDataForMerchantGainFromMarket;
