import React from 'react';
import styles from './place-what.module.css';
import Filters from '../../components/filters/filters';
import PlaceSalesPerShopper from '../../components/place-sales-per-shopper/place-sales-per-shopper';
import PlaceCategorySKUShare from '../../components/place-category-sku-share/place-category-sku-share';
import PlaceDistributionWidth from '../../components/place-distribution-width/place-distribution-width';
import PlaceDistributionDeepth from '../../components/place-distribution-deepth/place-distribution-deepth';
import PlaceDistributionDeepthSKULvl from '../../components/place-distribution-deepth-sku-lvl/place-distribution-deepth-sku-lvl';
import FloatingToast from '../../components/toast-for-switching-place/floating-toast';

const PlaceWhat: React.FC = () => {
  return (
    <div>
      <Filters />
      <div className={styles.wrapper}>
        <div className={styles.box1}>
          <PlaceSalesPerShopper />
        </div>
        <div className={styles.box2}>
          <PlaceCategorySKUShare />
        </div>
        <div className={styles.box3}>
          <PlaceDistributionWidth />
        </div>
        <div className={styles.box4}>
          <PlaceDistributionDeepthSKULvl />
        </div>
        <div className={styles.box5}>
        </div>
        <div className={styles.box6}>
          <PlaceDistributionDeepth />
        </div>
      </div>
      <FloatingToast bold={'what'} />
    </div>
  );
};

export default PlaceWhat;
