import React from 'react';
import styles from './promotion-who.module.css';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import Filters from '../../components/filters/filters';
import observableFiltersStore from '../../stores/filters';
import getPromotionUserData, { PromotionUserDataModel } from '../../client/get-promotion-user-data';
import Geography from '../../components/product-geography/geography';
import Age from '../../components/product-age/age';
import Consumption from '../../components/product-consumption/consumption';
import FamilyType from '../../components/product-family-type/family-type';
import FloatingToast from '../../components/toast-for-switching-promotion/floating-toast';
import PromotionLossAndGain from '../../components/promotion-loss-and-gain-for-promotion-who/promotion-loss-and-gain';

const PromotionWho: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [week, setWeek] = React.useState<string>('');
  const [type, setType] = React.useState<string>('');
  const [data, setData] = React.useState<PromotionUserDataModel>();
  
  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const result = await getPromotionUserData(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
        week,
        type,
      );

      if (result.success) {
        setData(result.data);
      }
      
      setIsLoading(false);
    });

    return () => dispose();
  }, [week, type]);
  
  return (
    <div>
      <Filters />
      <div className={styles.wrapper}>
        <div className={styles.box1}>
          <PromotionLossAndGain week={week} setWeek={setWeek} type={type} setType={setType} />
        </div>
        <div className={styles.box2}>
          <Geography isLoading={isLoading} name={'Week'} value={week} name2={'Type'} value2={type} data={data && data.geography ? data.geography : []} />
        </div>
        <div className={styles.box3}>
          <Age isLoading={isLoading} name={'Week'} value={week} name2={'Type'} value2={type} data={data && data.age ? data.age : []} />
        </div>
        <div className={styles.box4}>
          <Consumption isLoading={isLoading} name={'Week'} value={week} name2={'Type'} value2={type} data={data && data.consumption ? data.consumption : []} />
        </div>
        <div className={styles.box5}>
          <FamilyType isLoading={isLoading} name={'Week'} value={week} name2={'Type'} value2={type} data={data && data.familyType ? data.familyType : []} />
        </div>
      </div>
      <FloatingToast bold={'who'} />
    </div>
  );
};

export default observer(PromotionWho);
