import React, { useEffect, useState } from 'react';
import { autorun } from 'mobx';
import styles from './place-market-distribution-width.module.css';
import dateFormat from 'date-fns/format';
import { ResponsiveLine } from '@nivo/line';
import observableFiltersStore from '../../stores/filters';
import getPlaceMarketDistributionWidth from '../../client/get-place-market-distribution-width';

const PlaceMarketDistributionWidth: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const result = await getPlaceMarketDistributionWidth(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
      );

      if (result.success) {
        setData(result.data);
      }

      setIsLoading(false);
    });

    return () => dispose();
  }, []);


  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2>DISTRIBUTION WIDTH (numeric)</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
        {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
        {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
        <p>Source: Heco et al</p>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: 'red'}}></div>
            <div className={styles.item}>Distribution width</div>
          </div>
        </div>
      </div>
      <div className={styles.graph}>
        {isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
        {!isLoading && data && data.length > 0 &&
          <ResponsiveLine
            data={data}
            colors={['red']}
            margin={{ top: 10, right: 110, bottom: 50, left: 30 }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 'auto',
              stacked: false,
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Retailer',
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: data[0].data.length > 20 ? 8 : data[0].data.length > 15 ? 10 : 12,
                    fontWeight: 'bold',
                  },
                },
              },
            }}
            pointSize={5}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
          />
        }
      </div>
    </div>
  );
};

export default PlaceMarketDistributionWidth;
