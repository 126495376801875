import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

interface NotInTheShopProps {
  data: {
    Merchant: string;
    'Not in the shop': number;
  }[],
}

const NotInTheShopChart: React.FC<NotInTheShopProps> = (props: NotInTheShopProps) => {
  return (
    <ResponsiveBar
      data={props.data}
      markers={[
        {
          axis: 'y',
          value: 0,
          lineStyle: { stroke: 'rgba(0, 0, 0, 0.9)', strokeWidth: 1 },
          legend: '',
          legendOrientation: 'vertical',
        },
      ]}
      keys={['Not in the shop']}
      indexBy="Merchant"
      margin={{ top: 30, right: 130, bottom: 20, left: 30 }}
      padding={0.7}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={['#004F74']}
      axisTop={null}
      axisRight={null}
      borderWidth={1}
      borderColor="black"
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 1,
        tickRotation: 0,
      }}
      isInteractive={false}
      labelSkipWidth={12}
      labelTextColor="white"
      enableGridY={false}
      role="application"
      ariaLabel="Merchant Not in the Shop Chart"
      barAriaLabel={function(e) {
        return e.id+': '+e.formattedValue+' in merchant: '+e.indexValue;
      }}
    />
  );
};

export default NotInTheShopChart;
