import React from 'react';
import styles from './filters.module.css';
import { observer } from 'mobx-react-lite';
import { autorun, runInAction } from 'mobx';
import observableFiltersStore from '../../stores/filters';
import getFilterValues from '../../client/get-filter-values';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import daLocale from 'date-fns/locale/da';

const Filters: React.FC = () => {
  React.useEffect(() => {
    const dispose = autorun(async () => {
      observableFiltersStore.setIsLoading(true);
      const result = await getFilterValues(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
      );

      if (result.success) {
        runInAction(() => {
          observableFiltersStore.setIsLoading(false);
          observableFiltersStore.setAvailableBrands(result.data.brands);
          observableFiltersStore.setAvailableCategories(result.data.categories);
          observableFiltersStore.setAvailableMerchants(result.data.merchants);
          if (result.data.defaultCategory) observableFiltersStore.setDefaultCategory(result.data.defaultCategory);
        });
      }
    });

    return () => dispose();
  }, []);

  return (
    <div className={styles.wrap}>
      <Autocomplete
        multiple
        className={styles.filter}
        options={observableFiltersStore.availableMerchants}
        getOptionLabel={(option) => option.merchant_id.toUpperCase()}
        renderInput={(params) => <TextField {...params} label="Market" />}
        disabled={observableFiltersStore.isLoading}
        onChange={(event, newValue) => {
          runInAction(() => {
            observableFiltersStore.setMerchants(newValue || null);
          });
        }}
      />

      <Autocomplete
        className={`${styles.filter} ${styles.category}`}
        options={observableFiltersStore.availableCategories}
        getOptionLabel={(option) => option.name.toUpperCase()}
        renderInput={(params) => <TextField {...params} label="Category" />}
        disabled={observableFiltersStore.isLoading}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={observableFiltersStore.optiusCategory || observableFiltersStore.defaultCategory}
        onChange={(event, newValue) => {
          if (newValue) {
            runInAction(() => {
              observableFiltersStore.setOptiusCategory(newValue);
            });
          }
        }}
      />

      <Autocomplete
        multiple
        className={styles.filter}
        options={observableFiltersStore.availableBrands}
        getOptionLabel={(option) => option.name?.toUpperCase()}
        renderInput={(params) => <TextField {...params} label="Brand" />}
        disabled={observableFiltersStore.isLoading}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event, newValue) => {
          runInAction(() => {
            observableFiltersStore.setBrands(newValue || null);
          });
        }}
      />

      <LocalizationProvider dateAdapter={AdapterDateFns} locale={daLocale}>
        <DatePicker
          label="Date from"
          value={observableFiltersStore.dateFrom}
          onChange={newValue => {
            if (newValue && newValue > new Date('2013-01-01')) {
              observableFiltersStore.setDateFrom(newValue);
            }
          }}
          renderInput={(params) => <TextField className={styles.datepicker} {...params} />}
          disabled={observableFiltersStore.isLoading}
        />
        <DatePicker
          label="Date to"
          value={observableFiltersStore.dateTo}
          onChange={newValue => {
            if (newValue && newValue > new Date('2013-01-01')) {
              observableFiltersStore.setDateTo(newValue);
            }
          }}
          renderInput={(params) => <TextField className={styles.datepicker} {...params} />}
          disabled={observableFiltersStore.isLoading}
        />
      </LocalizationProvider>
    </div>
  );
};

export default observer(Filters);