import React from 'react';
import styles from './brands-average-prices.module.css';
import { ResponsiveBar } from '@nivo/bar';
import { autorun } from 'mobx';
import dateFormat from 'date-fns/format';
import observableFiltersStore from '../../stores/filters';
import getBrandsAveragePrices from '../../client/get-brands-average-prices';

const BrandsAveragePrices: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>();

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const result = await getBrandsAveragePrices(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
      );

      if (result.success) {
        setData(result.data);
      }
      setIsLoading(false);
    });

    return () => dispose();
  }, []);

  return (
    <>
      <div className={styles.wrap}>
        <div>
          <h2>AVERAGE PRICE</h2>
          <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
          <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
          {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
          <p>Source: Heco et al</p>
        </div>
        <div className={styles.graph}>
          {isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
          {!isLoading && data && data.brandNames &&
            <ResponsiveBar
              data={data.data}
              keys={data.brandNames}
              indexBy="interval"
              margin={{ top: 30, right: 130, bottom: 30, left: 0 }}
              padding={0.4}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={data.colors}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 10,
                tickPadding: 1,
                tickRotation: 0,
              }}
              axisLeft={null}
              labelSkipWidth={12}
              labelSkipHeight={6}
              labelTextColor="black"
              theme={{
                axis: {
                  ticks: {
                    text: {
                      fontSize: data.data.length > 20 ? 7 : data.data.length > 15 ? 9 : 11,
                      fontWeight: 'bold',
                    },
                  },
                },
              }}
              enableGridY={false}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'top-right',
                  direction: 'column',
                  justify: false,
                  translateX: 110,
                  translateY: -15,
                  itemsSpacing: 3,
                  itemWidth: 116,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 14,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Brands Average Prices Bar Chart"
              barAriaLabel={function(e) {
                return e.id+': '+e.formattedValue+' in merchant: '+e.indexValue;
              }}
            />
          }
        </div>
      </div>
    </>
  );
};

export default BrandsAveragePrices;
