import React from 'react';
import styles from './brand-loss-and-gain.module.css';
import { observer } from 'mobx-react-lite';
import { ResponsiveBar } from '@nivo/bar';
import dateFormat from 'date-fns/format';
import observableFiltersStore from '../../stores/filters';

export interface LossAndGainProps {
  isLoading: boolean,
  data: any[],
  topSellingBrands: string[],
  brand?: string,
  typeOfChart?: string,
  setBrand: (brand: string) => void,
  setBrand2: (brand: string) => void,
  setTypeOfChart: (typeOfChart: string) => void,
}

const BrandLossAndGain: React.FC<LossAndGainProps> = (props: LossAndGainProps) => {
  return (
    <>
      <div className={styles.header}>
        <h2>LOSS {`&`} GAIN</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
        {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
        {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
        <p>Source: Heco et al</p>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#24B8FD'}}></div>
            <div className={styles.item}>Gained to category in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#A3E2FF'}}></div>
            <div className={styles.item}>Gain from market in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#008FD5'}}></div>
            <div className={styles.item}>Loss to market in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#004F74'}}></div>
            <div className={styles.item}>Lost from category in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: 'white'}}></div>
            <div className={styles.item}>+/- Growth in %</div>
          </div>
        </div>
      </div>
      <div style={{height: '300px'}}>
        {props.isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
        {!props.isLoading && props.data && (
          <ResponsiveBar
            data={props.data.filter(dataObject => ((!observableFiltersStore.brands || observableFiltersStore.brands.length === 0) && props.topSellingBrands.includes(dataObject.Brand)) || (observableFiltersStore.brands && observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toLowerCase()], []).includes(dataObject.Brand.toLowerCase())))}
            keys={['Gain from market in %', 'Loss to market in %', 'Gained to category in %', 'Lost from category in %', '+/- Growth in %']}
            indexBy="Brand"
            margin={{ top: 20, right: 25, bottom: 40, left: 50 }}
            padding={0.4}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={['#A3E2FF', '#008FD5', '#24B8FD', '#004F74', 'white']}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 10,
              tickPadding: 1,
              tickRotation: 0,
            }}
            axisLeft={{
              tickSize: 2,
              tickPadding: 1,
              tickRotation: 0,
            }}
            labelSkipWidth={12}
            labelSkipHeight={0}
            labelTextColor="black"
            onClick={(data) => {
              if (props.brand === data['indexValue'].toString() && props.typeOfChart === data['id'].toString().slice(0, -5)) return;
              if (props.brand !== data['indexValue'].toString() && props.typeOfChart === data['id'].toString().slice(0, -5)) {
                props.setBrand(data['indexValue'].toString());
                props.setBrand2('');
              } else if (props.brand === data['indexValue'].toString() && props.typeOfChart !== data['id'].toString().slice(0, -5))  {
                props.setTypeOfChart(data['id'].toString().slice(0, -5));
                props.setBrand2('');
              } else {
                props.setTypeOfChart(data['id'].toString().slice(0, -5));
                props.setBrand(data['indexValue'].toString());
                props.setBrand2('');
              }
            }}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 12,
                    fontWeight: 'bold',
                  },
                },
              },
            }}
            enableGridY={false}
            role="application"
            ariaLabel="Brand Loss and Gain Bar Chart"
            barAriaLabel={function(e) {
              return e.id+': '+e.formattedValue+' in brand: '+e.indexValue;
            }}
          />
        )}
      </div>
    </>
  );
};

export default observer(BrandLossAndGain);
