const merchantSharing = (merchantSharingName: string, merchantsSharing: Record<string, Record<string, number>>) => {

  const merchantSharing: Record<string, number> = {};
  let sumSharing = 0;

  for (const merchantColumnName of Object.keys(merchantsSharing[merchantSharingName])) {
    merchantSharing[merchantColumnName] = merchantsSharing[merchantSharingName][merchantColumnName];
    sumSharing += merchantSharing[merchantColumnName];
  }

  merchantSharing[merchantSharingName] = sumSharing;

  return merchantSharing;
};

export default merchantSharing;
