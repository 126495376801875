import React from 'react';
import { Outlet } from 'react-router-dom';
import LeftMenu from '../left-menu/left-menu';
import styles from './dashboard-layout.module.css';

const DashboardLayout: React.FC<{alt?: boolean}> = props => {
  return (
    <div className={styles.container}>
      <LeftMenu />
      <Outlet />
    </div>
  );
};

export default DashboardLayout;