import React, { useState } from 'react';
import { autorun } from 'mobx';
import observableFiltersStore from '../../stores/filters';
import styles from './category-where-why.module.css';
import Filters from '../../components/filters/filters';
import FloatingToast from '../../components/toast-for-switching-category/floating-toast';
import LossAndGain from '../../components/loss-and-gain/loss-and-gain';
import getLossAndGain from '../../client/get-loss-and-gain';
import prepareDataForLossAndGain from '../../components/loss-and-gain/prepareDataForLossAndGain';
import Duplication from '../../components/duplication/duplication';
import getDuplication from '../../client/get-duplication';
import calculateMatrix from '../../components/loss-and-gain/calculateMatrix';
import Transition from '../../components/transition/transition';
import TopAssortment from '../../components/top-assortment/top-assortment';

export interface LossAndGainLastTableColumns {
  P1: number,
  P2: number,
  Calc: number,
  'Calc in %': number,
  Gain: number, 
  'Gain in %': number,
  Loss: number,
  'Loss in %': number,
  Increase: number, 
  'Increase in %': number,
  Decrease: number,
  'Decrease in %': number,
}

export interface MerchantLossAndGain {
  'Merchant': string,
  'Gained to category in %': number,
  'Gain from market in %': number,
  'Loss to market in %': number,
  'Lost from category in %': number,
  '+/- Growth in %': number,
}

export interface MerchantDuplication {
  'Merchant': string,
  'Only at': number,
  'Sharing': number,
  'Elsewhere': number,
  'Not in the shop': number,
}

export interface MerchantDuplicationAndTransition {
  dataDuplication: MerchantDuplication[],
  merchantsSharing: Record<string, Record<string, number>>,
  merchantsElsewhere: Record<string, Record<string, number>>,
}

const CategoryWhereWhy: React.FC = () => {

  const [isLoadingLossAndGain, setIsLoadingLossAndGain] = React.useState<boolean>(true);
  const [isLoadingDuplication, setIsLoadingDuplication] = React.useState<boolean>(true);

  const [rawData, setRawData] = useState<Record<string, Record<string, number>>>();
  const [matrix, setMatrix] = useState<Record<string, LossAndGainLastTableColumns>>();
  const [dataLossAndGain, setDataLossAndGain] = React.useState<MerchantLossAndGain[]>();
  const [merchant, setMerchant] = useState<string | undefined>();
  const [merchant2, setMerchant2] = useState<string | undefined>();
  const [typeOfChart, setTypeOfChart] = useState<string | undefined>();

  const [dataDuplicationAndTransition, setDataDuplicationAndTransition] = React.useState<MerchantDuplicationAndTransition>();

  React.useEffect(() => {
    const dispose = autorun(async () => {

      setIsLoadingLossAndGain(true);
      setIsLoadingDuplication(true);

      const resultLossAndGain = await getLossAndGain(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
      );

      setMerchant(undefined);
      setTypeOfChart(undefined);

      if (resultLossAndGain.success) {
        setRawData({...resultLossAndGain.data});
  
        const matrix = calculateMatrix({...resultLossAndGain.data});
        setMatrix(matrix);
  
        setDataLossAndGain(() => prepareDataForLossAndGain({...matrix}));
      }

      setIsLoadingLossAndGain(false);

      const resultDuplication = await getDuplication(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
      );

      if (resultDuplication.success) {
        setDataDuplicationAndTransition(resultDuplication.data);
      }

      setIsLoadingDuplication(false);

    });

    return () => dispose();
  }, []);

  return (
    <div className={styles.mainContainer}>
      <Filters />
      <div className={styles.topContainer}>
        <div className={styles.topsChild}>
          <LossAndGain merchant={merchant} typeOfChart={typeOfChart} isLoading={isLoadingLossAndGain} setMerchant={setMerchant} setMerchant2={setMerchant2} setTypeOfChart={setTypeOfChart} data={dataLossAndGain} />
        </div>
        <div className={styles.topsChild}>
          <Duplication merchant={merchant} typeOfChart={typeOfChart} isLoading={isLoadingDuplication} setMerchant={setMerchant} setMerchant2={setMerchant2} setTypeOfChart={setTypeOfChart} data={dataDuplicationAndTransition ? dataDuplicationAndTransition.dataDuplication : []} />
        </div>
      </div>
      <div className={styles.middleContainer}>
        {rawData && matrix && dataDuplicationAndTransition &&
          <Transition merchant={merchant} setMerchant2={setMerchant2} typeOfChart={typeOfChart} rawData={rawData} matrix={matrix} dataDuplicationAndTransition={dataDuplicationAndTransition} />
        }
      </div>
      <FloatingToast bold={'where-why'} />
    </div>
  );
};

export default CategoryWhereWhy;
