import {Result} from './models';

const createUser = async (name: string, email: string, password: string, key: string, subscribe: boolean): Promise<Result> => {
  const response = await fetch(`${process.env.REACT_APP_API}/v1/create-user`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      email,
      password,
      key,
      subscribe,
    }),
  });
  return await response.json();
};

export default createUser;