import { LossAndGainLastTableColumns } from '../../../containers/category-where-why/category-where-why';

const merchantGainFromMarket = (merchantGaining: string, merchantRows: Record<string, Record<string, number>>, matrix: Record<string, LossAndGainLastTableColumns>) => {

  const merchantGainFromMarket: Record<string, number> = {};
  let sumGainFromMarket = 0;

  for (const merchantName of Object.keys(merchantRows)) {

    if (merchantName === 'Increase' || merchantName === 'Total') continue;
    
    if (merchantName === merchantGaining) {
      merchantGainFromMarket[merchantName] = matrix[merchantName]['Gain in %'];
      continue;
    }

    if (matrix[merchantGaining]['P1'] !== 0) {
      merchantGainFromMarket[merchantName] = Math.round(10000 * (merchantRows[merchantName][merchantGaining] / matrix[merchantGaining]['P1'])) / 100;
    } else { // cannot perform the standard distribution of gain (loss) because of p1 equaling 0
      merchantGainFromMarket[merchantName] = 0;
      sumGainFromMarket = 100;
    }

    sumGainFromMarket += merchantGainFromMarket[merchantName];

  }

  return {
    'merchantGainFromMarket': merchantGainFromMarket,
    'sumGainFromMarket': Math.round(100 * sumGainFromMarket) / 100,
  };
};

export default merchantGainFromMarket;
