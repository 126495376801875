import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import Logo from '../../components/logo/logo';
import styles from './login.module.css';
import Arrow from '../../components/arrow/arrow';
import Alert from '@mui/material/Alert';
import { browserLocalPersistence, browserSessionPersistence, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import observableUiStore from '../../stores/ui';

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [remember, setRemember] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!email) return setError('Email is a required field.');
    if (!password) return setError('Password is a required field.');

    setError('');
    observableUiStore.setIsLoading(true);
    const auth = getAuth();
    await auth.setPersistence(remember ? browserLocalPersistence : browserSessionPersistence);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      observableUiStore.setIsLoading(false);
      navigate('/', {replace: true});
    } catch (e) {
      observableUiStore.setIsLoading(false);
      if (e.code === 'auth/user-not-found' || e.code === 'auth/wrong-password' || e.code === 'auth/invalid-email') {
        setError('Incorrect email or password.');
      } else {
        setError(e.message || 'An unexpected error has occurred. Please try again.');
      }
    }
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <Logo className={styles.logo} />
        <TextField className={styles.input} variant="outlined" value={email} type="email" label="Work email" onChange={e => setEmail(e.target.value)} fullWidth />
        <TextField className={styles.input} variant="outlined" value={password} type="password" label="Password" onChange={e => setPassword(e.target.value)} fullWidth />
        <FormControlLabel className={styles.checkbox} control={<Checkbox checked={remember} onChange={e => setRemember(e.target.checked)} />} label="Remember me" />

        <Button className={styles.button} fullWidth variant="contained" color="secondary" startIcon={<Arrow color="primary" />} onClick={handleLogin}>Log in</Button>
        <a className={styles.contact} href="https://hecoetal.com/contact">Don't have an account? Get in touch!</a>
        <Link className={styles.forgotPassword} to="/auth/forgot-password">Forgot password?</Link>
        {error ? <Alert severity="error" className={styles.alert}>{error}</Alert> : null}
      </div>
    </div>
  );
};

export default Login;