import React from 'react';
import { useNavigate } from 'react-router-dom';
import Arrow from '../arrow/arrow';
import styles from './logo.module.css';

interface Props {
  className?: string;
}

const Logo: React.FC<Props> = props => {
  const navigate = useNavigate();

  return (
    <div className={`${styles.logo} ${props.className || ''}`} onClick={() => navigate('/')}>
      <Arrow fontSize="inherit" color="primary" />
      <h1>Heco et al</h1>
    </div>
  );
};

export default Logo;