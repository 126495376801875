import React from 'react';
import styles from './tree-item.module.css';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  currentValue: number;
  lastYearPeriod: number;
  measureSign: string;
  maximumFractionDigits: number;
  itemMinWidth?: number;
  shoppers?: number;
}

const TreeItem: React.FC<Props> = props => {
  const previousPeriodChange = (props.currentValue - props.lastYearPeriod) / props.lastYearPeriod * 100;
  const tooltipValue = props.lastYearPeriod >= 10000 ? 
    (props.lastYearPeriod / 1000).toLocaleString('da-DK', {maximumFractionDigits: props.maximumFractionDigits}) + 'K' 
    :
    props.lastYearPeriod.toLocaleString('da-DK', {maximumFractionDigits: props.maximumFractionDigits}) + props.measureSign;

  return (
    <div className={styles.item} style={{minWidth: props.itemMinWidth ? props.itemMinWidth + 'rem' : '', gridTemplateColumns: props.shoppers ? '100%' : ''}}>
      <div className={styles.leftValueContainer}>
        {
          props.currentValue >= 10000 ?
            (props.currentValue / 1000).toLocaleString('da-DK', {maximumFractionDigits: props.maximumFractionDigits}) + 'K'
            :
            props.currentValue.toLocaleString('da-DK', {maximumFractionDigits: props.maximumFractionDigits})
        } 
        {props.measureSign === 'kr.' ? <br /> : null}
        {props.measureSign}
      </div>
      {
        !props.shoppers && 
          <Tooltip title={!props.shoppers ? `Previous period: ${tooltipValue}` : `Current period shoppers: ${props.shoppers}. Last year period shoppers: ${props.lastYearPeriod}`} placement="right-end">
            <div className={styles.rightValueContainer}>
              {previousPeriodChange > 0 ? <ArrowDropUpIcon className={styles.arrow} /> : <ArrowDropDownIcon className={styles.arrow} />}
              {Math.abs(previousPeriodChange).toLocaleString('da-DK', {maximumFractionDigits: 1})}{!props.shoppers && '%'}
            </div>
          </Tooltip>
      }
    </div>
  );
};

export default TreeItem;