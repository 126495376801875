import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

interface Props {
  data: any,
  setMerchant2: (merchant: string) => void,
  merchant: string,
}

const GainFromMarketChart: React.FC<Props> = (props: Props) => {

  React.useEffect(() => {
    if (props.data.length > 1) {
      props.setMerchant2(props.data[1]['Merchant'].toLowerCase());
    } else {
      props.setMerchant2('');
    }

  }, [props.merchant]);

  return (
    <ResponsiveBar
      data={props.data}
      markers={[
        {
          axis: 'y',
          value: 0,
          lineStyle: { stroke: 'rgba(0, 0, 0, 0.9)', strokeWidth: 1 },
          legend: '',
          legendOrientation: 'vertical',
        },
      ]}
      keys={['Whitespace', 'Gained', 'Lost']}
      indexBy="Merchant"
      margin={{ top: 30, right: 130, bottom: 20, left: 30 }}
      padding={0.4}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={['white', '#A3E2FF', 'lightgray']}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 1,
        tickRotation: 0,
      }}
      onClick={(data) => {
        if (props.data[0]['Merchant'] !== data['indexValue'].toString()) {
          props.setMerchant2(data['indexValue'].toString().toLowerCase());
        }
      }}
      isInteractive={true}
      tooltip={(data) => (<>{data['indexValue']}</>)}
      labelSkipWidth={12}
      labelTextColor="white"
      enableGridY={false}
      role="application"
      ariaLabel="Merchant Gain from Market Chart"
      barAriaLabel={function(e) {
        return e.id+': '+e.formattedValue+' in merchant: '+e.indexValue;
      }}
    />
  );
};

export default GainFromMarketChart;
