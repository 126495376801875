interface MerchantLossMarket {
  'Merchant': string,
  'Lost': number,
  'Gained': number,
  'Whitespace': number,
}

interface MerchantLossData{
  merchantLossToMarket: Record<string, number>,
  sumLossToMarket: number,
}

const formatDataForMerchantLossToMarket = (merchant: string, merchantLossData: MerchantLossData) => {
  
  const formatedData: [string, number][] = Object.entries(merchantLossData.merchantLossToMarket);
  const sumLossToMarket = merchantLossData.sumLossToMarket;
  let whiteSpaceLeft = sumLossToMarket;

  formatedData.sort((array1: [string, number], array2: [string, number]) => { // Array of arrays, where the inner arrays contain only 2 elements, 1st a string, 2nd a number 
    if (array1[1] < array2[1]) {
      return -1;
    }
    if (array1[1] > array2[1]) {
      return 1;
    }
    return 0;
  });

  const index = formatedData.findIndex(array => array[0] === merchant);
  const merchantLosing = formatedData[index];
  formatedData.splice(index, 1);
  formatedData.splice(0, 0, merchantLosing);

  const data: MerchantLossMarket[] = [];
  for (let i = 0; i < formatedData.length; i++) {
    if (formatedData[i][0] === merchant) {
      data.push({
        'Merchant': merchant,
        'Lost': -sumLossToMarket,
        'Gained': 0,
        'Whitespace': 0,
      });
      continue;
    };
    if (formatedData[i][1] === 0) break;
    
    const objectForMerchant: MerchantLossMarket = {
      'Merchant': formatedData[i][0],
      'Lost': 0,
      'Gained': Number(formatedData[i][1]),
      'Whitespace': whiteSpaceLeft > 0 ? -(Number(formatedData[i][1]) + whiteSpaceLeft) : 0,
    };
    data.push(objectForMerchant);
    whiteSpaceLeft = -data[data.length - 1]['Whitespace'];
  };

  return data;
};

export default formatDataForMerchantLossToMarket;
