import React from 'react';
import styles from './loader.module.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite';
import observableUiStore from '../../stores/ui';

const Loader: React.FC = () => {
  return (
    <Backdrop className={styles.backdrop} open={observableUiStore.isLoading}>
      <CircularProgress className={styles.loader} color="inherit" />
    </Backdrop>
  );
};

export default observer(Loader);