import React from 'react';
import styles from './duplication.module.css';
import { ResponsiveBar } from '@nivo/bar';
import { observer } from 'mobx-react-lite';
import dateFormat from 'date-fns/format';
import observableFiltersStore from '../../stores/filters';

export interface DuplicationProps {
  isLoading: boolean,
  data: any[],
  merchant?: string,
  typeOfChart?: string,
  setMerchant: (merchant: string) => void,
  setMerchant2: (merchant: string) => void,
  setTypeOfChart: (typeOfChart: string) => void,
}

const Duplication: React.FC<DuplicationProps> = (props: DuplicationProps) => {

  const data = [];

  for (let i = 0; i < props.data.length; i++) {
    const obj = {
      'Merchant': props.data[i]['Merchant'],
      'Only at in %': props.data[i]['Only at'],
      'Sharing in %': props.data[i]['Sharing'],
      'Elsewhere in %': props.data[i]['Elsewhere'],
      'Not in the shop in %': props.data[i]['Not in the shop'],
    };
    data.push(obj);
  }

  data.sort((e1: any, e2: any) => {
    if (e1['Sharing in %'] < e2['Sharing in %']) {
      return 1;
    } else if (e1['Sharing in %'] > e2['Sharing in %']) {
      return -1;
    }
    return 0;
  });

  return (
    <>
      <div className={styles.header}>
        <h2>DUPLICATION</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
        {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
        {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
        <p>Source: Heco et al</p>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#24B8FD'}}></div>
            <div className={styles.item}>Only at in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#A3E2FF'}}></div>
            <div className={styles.item}>Sharing in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#008FD5'}}></div>
            <div className={styles.item}>Elsewhere in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#004F74'}}></div>
            <div className={styles.item}>Not in the shop in %</div>
          </div>
        </div>
      </div>
      <div style={{height: '300px', width: '98%'}}>
        {props.isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
        {!props.isLoading && props.data && (
          <ResponsiveBar
            data={data.filter(merchantObject => (!observableFiltersStore.merchants || observableFiltersStore.merchants.length === 0) || observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toLowerCase()], []).includes(merchantObject.Merchant.toLowerCase()))}
            keys={['Sharing in %', 'Only at in %', 'Elsewhere in %', 'Not in the shop in %']}
            indexBy="Merchant"
            margin={{ top: 20, right: 25, bottom: 40, left: 50 }}
            padding={0.4}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={['#A3E2FF', '#24B8FD', '#008FD5', '#004F74']}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 10,
              tickPadding: 1,
              tickRotation: 0,
            }}
            axisLeft={{
              tickSize: 2,
              tickPadding: 1,
              tickRotation: 0,
            }}
            labelSkipWidth={12}
            labelSkipHeight={0}
            labelTextColor="black"
            onClick={(data) => {
              if (props.merchant === data['indexValue'].toString() && props.typeOfChart === data['id'].toString().slice(0, -5)) return;
              if (props.merchant !== data['indexValue'].toString() && props.typeOfChart === data['id'].toString().slice(0, -5)) {
                props.setMerchant(data['indexValue'].toString());
                props.setMerchant2('');
              } else if (props.merchant === data['indexValue'].toString() && props.typeOfChart !== data['id'].toString().slice(0, -5))  {
                props.setTypeOfChart(data['id'].toString().slice(0, -5));
                props.setMerchant2('');
              } else {
                props.setTypeOfChart(data['id'].toString().slice(0, -5));
                props.setMerchant(data['indexValue'].toString());
                props.setMerchant2('');
              }
            }}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: data.filter(merchantObject => (!observableFiltersStore.merchants || observableFiltersStore.merchants.length === 0) || observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toLowerCase()], []).includes(merchantObject.Merchant.toLowerCase())).length > 20 ? 7 : data.filter(merchantObject => (!observableFiltersStore.merchants || observableFiltersStore.merchants.length === 0) || observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toLowerCase()], []).includes(merchantObject.Merchant.toLowerCase())).length > 15 ? 9 : 11,
                    fontWeight: 'bold',
                  },
                },
              },
            }}
            enableGridY={false}
            role="application"
            ariaLabel="Duplication Bar Chart"
            barAriaLabel={function(e) {
              return e.id+': '+e.formattedValue+' in merchant: '+e.indexValue;
            }}
          />
        )}
      </div>
    </>
  );
};

export default observer(Duplication);
