import React from 'react';
import styles from './product-assortment.module.css';
import dateFormat from 'date-fns/format';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import observableFiltersStore from '../../stores/filters';
import getProductAssortment, { ProductAssortmentResultArrayObject } from '../../client/get-product-assortment';
import { ResponsiveBar } from '@nivo/bar';

interface ProductAssortmentProps {
  brand: string,
  type: string,
  setBrand: (brand: string) => void,
  setType: (type: string) => void,
}

const ProductAssortment: React.FC<ProductAssortmentProps> = (props: ProductAssortmentProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [dataBrandAssortment, setDataBrandAssortment] = React.useState<ProductAssortmentResultArrayObject[]>();
  const data = [];

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const resultProductAssortment = await getProductAssortment(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
      );

      if (resultProductAssortment.success) {
        setDataBrandAssortment(resultProductAssortment.data);
      }

      setIsLoading(false);
    });

    return () => dispose();
  }, []);

  if (dataBrandAssortment) {
    for (let i = 0; i < dataBrandAssortment.length; i++) {
      const obj = {
        'Brand': dataBrandAssortment[i]['Brand'],
        'Exclusive buyers in %': dataBrandAssortment[i]['Only at'],
        'Duplicator in %': dataBrandAssortment[i]['Sharing'],
      };
      data.push(obj);
    }
  
    data.sort((e1: any, e2: any) => {
      if (e1['Duplicator in %'] < e2['Duplicator in %']) {
        return -1;
      } else if (e1['Duplicator in %'] > e2['Duplicator in %']) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <div className={styles.info}>
          <h2>ASSORTMENT</h2>
          <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
          <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
          {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
          {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
          <p>Source: Heco et al</p>
        </div>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#A3E2FF'}}></div>
            <div className={styles.item}>Duplicator in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#24B8FD'}}></div>
            <div className={styles.item}>Exclusive buyers in %</div>
          </div>
        </div>
      </div>
      <div style={{height: '34rem'}}>
        {isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
        {!isLoading && data && (
          <ResponsiveBar
            data={data}
            onClick={(data) => {
              if (data['id'].toString() === 'Duplicator in %') {
                props.setType('Duplicator');
              } else {
                props.setType('Exclusive');
              }
              props.setBrand(data['indexValue'].toString());
            }}
            keys={['Duplicator in %', 'Exclusive buyers in %']}
            indexBy="Brand"
            margin={{ top: 0, right: 20, bottom: 0, left: 100 }}
            padding={0.4}
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={['#A3E2FF', '#24B8FD']}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={{
              tickSize: 2,
              tickPadding: 1,
              tickRotation: 0,
            }}
            labelSkipWidth={12}
            labelSkipHeight={3}
            labelTextColor="black"
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 10,
                    fontWeight: 'bold',
                  },
                },
              },
            }}
            enableGridY={false}
            role="application"
            ariaLabel="Product Assortment Bar Chart"
            barAriaLabel={function(e) {
              return e.id+': '+e.formattedValue+' in brand: '+e.indexValue;
            }}
          />
        )}
      </div>
      {props.brand && props.type &&
        <div className={styles.clearChoiceButton}>
          <div>Selected brand: {props.brand}</div>
          <div>Type: {props.type}</div>
          <button onClick={() => {
            props.setBrand(''); 
            props.setType('');
          }}>
            Clear choice
          </button>
        </div>
      }
    </div>
  );
};

export default observer(ProductAssortment);
