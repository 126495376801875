import React from 'react';
import styles from './place-where-why.module.css';
import Filters from '../../components/filters/filters';
import PlaceMarketSalesPerShopper from '../../components/place-market-sales-per-shopper/place-market-sales-per-shopper';
import FloatingToast from '../../components/toast-for-switching-place/floating-toast';
import PlaceMarketShopperTraficIndex from '../../components/place-market-shopper-trafic-index/place-market-shopper-trafic-index';
import PlaceMarketDistributionWidth from '../../components/place-market-distribution-width/place-market-distribution-width';
import PlaceMarketDistributionDeepthSKULvl from '../../components/place-market-distribution-deepth-sku-lvl/place-market-distribution-deepth-sku-lvl';
import PlaceMarketDistributionDeepth from '../../components/place-market-distribution-deepth/place-market-distribution-deepth';

const PlaceWhereWhy: React.FC = () => {
  return (
    <div>
      <Filters />
      <div className={styles.wrapper}>
        <div className={styles.box1}>
          <PlaceMarketSalesPerShopper />
        </div>
        <div className={styles.box2}>
          <PlaceMarketShopperTraficIndex />
        </div>
        <div className={styles.box3}>
          <PlaceMarketDistributionWidth />
        </div>
        <div className={styles.box4}>
          <PlaceMarketDistributionDeepthSKULvl />
        </div>
        <div className={styles.box5}>
        </div>
        <div className={styles.box6}>
          <PlaceMarketDistributionDeepth />
        </div>
      </div>
      <FloatingToast bold={'where-why'} />
    </div>
  );
};

export default PlaceWhereWhy;
