import { makeObservable, observable, action } from 'mobx';

export class UiStore {
  isLoading: boolean = false;

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      setIsLoading: action,
    });
  }

  setIsLoading(val: boolean) {
    this.isLoading = val;
  }
}

const observableUiStore = new UiStore();

export default observableUiStore;