import { getAuth } from 'firebase/auth';
import {Result} from './models';
import dateFormat from 'date-fns/format';

interface GeographyModel {
  code: string;
  count: number;
}

interface AgeModel {
  period: string;
  count: number;
}

interface ConsumptionPartModel {
  one_per_week: number,
  one_per_2weeks: number,
  one_per_30days: number,
  one_per_90days: number,
  one_per_180days: number,
  one_per_360days: number,
}

interface FamilyModel {
  id: string;
  count: number;
}

interface ConsumptionModel {
  categorization: ConsumptionPartModel,
  values: ConsumptionPartModel,
}

export interface ProductUserDataModel {
  geography: GeographyModel[],
  age: AgeModel[],
  consumption: ConsumptionModel,
  familyType: FamilyModel[],
}

const getProductUserData = async (dateFrom: Date, dateTo: Date, categoryId?: string, merchantIds?: string[], brandIds?: string[], brand?: string, type?: string): Promise<Result<ProductUserDataModel>> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();

  if (!token) {
    throw new Error('Unauthorized');
  }
  
  const response = await fetch(`${process.env.REACT_APP_API}/v1/get-product-user-data?date_from=${dateFormat(dateFrom, 'yyyy-MM-dd')}&date_to=${dateFormat(dateTo, 'yyyy-MM-dd')}&category_id=${categoryId || ''}&merchant_ids=${merchantIds?.reduce((acc: string[], curr) => acc = [...acc, encodeURIComponent(curr)], [])?.join(',') || ''}&brand_ids=${brandIds?.reduce((acc: string[], curr) => acc = [...acc, encodeURIComponent(curr)], [])?.join(',') || ''}&brand=${brand}&type=${type}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
  return await response.json();
};

export default getProductUserData;
