import {Result} from './models';

const resetPassword = async (userId: string, password: string, code: string): Promise<Result> => {
  const response = await fetch(`${process.env.REACT_APP_API}/v1/reset-password`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      password,
      code,
    }),
  });
  return await response.json();
};

export default resetPassword;