import { LossAndGainLastTableColumns } from '../../../containers/brand-where-why/brand-where-why';

const brandLostFromCategory = (brand: string, matrix: Record<string, LossAndGainLastTableColumns>) => {
  
  return [{
    'Brand': brand,
    'Lost': matrix[brand]['Decrease in %'],
    'Gained': 0,
  }];
};

export default brandLostFromCategory;
