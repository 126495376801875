import React from 'react';
import CategoryInitiatives from '../../components/category-initiatives/category-initiatives';
import CategoryValueDrivers from '../../components/category-value-drivers/category-value-drivers';
import Filters from '../../components/filters/filters';
import PenetrationFlow from '../../components/penetration-flow/penetration-flow';
import FloatingToast from '../../components/toast-for-switching-category/floating-toast';
import styles from './category-what.module.css';

const CategoryWhat: React.FC = () => {
  return (
    <div className={styles.wrap}>
      <Filters />
      <div className={styles.content}>
        <div className={styles.left}>
          <CategoryValueDrivers />
          <br />
          <CategoryInitiatives />
        </div>
        <div className={styles.right}>
          <PenetrationFlow height="43.5rem" />
        </div>
      </div>
      <FloatingToast bold={'what'} />
    </div>
  );
};

export default CategoryWhat;
