import { LossAndGainLastTableColumns, BrandLossAndGainInterface } from '../../containers/brand-where-why/brand-where-why';

const prepareDataForBrandLossAndGain = (matrix: Record<string, LossAndGainLastTableColumns>) => {

  const data: BrandLossAndGainInterface[] = [];

  for (const brandRowName in matrix) {
    const brandColumns = matrix[brandRowName];
    const objectForBrand: BrandLossAndGainInterface = {
      'Brand': brandRowName,
      'Gained to category in %': 0,
      'Gain from market in %': 0,
      'Loss to market in %': 0,
      'Lost from category in %': 0,
      '+/- Growth in %': 0,
    };
    let total = 0;
    for (const brandColumnName in brandColumns) {
      if (brandColumnName === 'Gain in %') {
        objectForBrand['Gain from market in %'] = brandColumns[brandColumnName];
        total += objectForBrand['Gain from market in %'];
      } else if (brandColumnName === 'Loss in %') {
        objectForBrand['Loss to market in %'] = brandColumns[brandColumnName];
        total += objectForBrand['Loss to market in %'];
      } else if (brandColumnName === 'Increase in %') {
        objectForBrand['Gained to category in %'] = brandColumns[brandColumnName];
        total += objectForBrand['Gained to category in %'];
      } else if (brandColumnName === 'Decrease in %') {
        objectForBrand['Lost from category in %'] = brandColumns[brandColumnName];
        total += objectForBrand['Lost from category in %'];
      }
    }
    objectForBrand['+/- Growth in %'] = total;
    data.push(objectForBrand);
  }

  data.sort((e1: BrandLossAndGainInterface, e2: BrandLossAndGainInterface) => {
    if (e1['+/- Growth in %'] < e2['+/- Growth in %']) {
      return 1;
    } else if (e1['+/- Growth in %'] > e2['+/- Growth in %']) {
      return -1;
    }
    return 0;
  });

  return data;
};

export default prepareDataForBrandLossAndGain;
