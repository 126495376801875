const merchantElsewhere = (merchantElsewhereName: string, merchantsElsewhere: Record<string, Record<string, number>>) => {

  const merchantElsewhere: Record<string, number> = {};
  let sumElsewhere = 0;

  for (const merchantColumnName of Object.keys(merchantsElsewhere[merchantElsewhereName])) {
    merchantElsewhere[merchantColumnName] = merchantsElsewhere[merchantElsewhereName][merchantColumnName];
    sumElsewhere += merchantElsewhere[merchantColumnName];
  }

  merchantElsewhere[merchantElsewhereName] = sumElsewhere;

  return merchantElsewhere;
};

export default merchantElsewhere;
