interface MerchantElsewhere {
  'Merchant': string,
  'Elsewhere from': number,
  'Elsewhere to': number,
  'Whitespace': number,
}

const formatDataForMerchantElsewhere = (merchant: string, merchantElsewhere: Record<string, number>) => {
  
  const formatedData: [string, number][] = Object.entries(merchantElsewhere);
  let whiteSpaceLeft = merchantElsewhere[merchant];

  formatedData.sort((array1: [string, number], array2: [string, number]) => { // Array of arrays, where the inner arrays contain only 2 elements, 1st a string, 2nd a number 
    if (array1[1] < array2[1]) {
      return -1;
    }
    if (array1[1] > array2[1]) {
      return 1;
    }
    return 0;
  });

  const index = formatedData.findIndex(array => array[0] === merchant);
  const merchantElsewhereFrom = formatedData[index];
  formatedData.splice(index, 1);
  formatedData.splice(0, 0, merchantElsewhereFrom);

  const data: MerchantElsewhere[] = [];
  for (let i = 0; i < formatedData.length; i++) {
    formatedData[i][1] = Math.round(100 * formatedData[i][1]) / 100;
    if (formatedData[i][0] === merchant) {
      data.push({
        'Merchant': merchant,
        'Elsewhere from': formatedData[i][1],
        'Elsewhere to': 0,
        'Whitespace': 0,
      });
      continue;
    };
    if (formatedData[i][1] === 0) break;
    
    const objectForMerchant: MerchantElsewhere = {
      'Merchant': formatedData[i][0],
      'Elsewhere from': 0,
      'Elsewhere to': formatedData[i][1],
      'Whitespace': whiteSpaceLeft < 0 ? whiteSpaceLeft - formatedData[i][1] : 0,
    };
    data.push(objectForMerchant);
    whiteSpaceLeft = data[data.length - 1]['Whitespace'];
  };

  return data;
};

export default formatDataForMerchantElsewhere;
