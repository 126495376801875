import React from 'react';
import styles from './category-initiatives.module.css';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import observableFiltersStore from '../../stores/filters';
import dateFormat from 'date-fns/format';
import TreeItem from '../tree-item/tree-item';
import getCategoryInitiatives, { CategoryInitiativesResult } from '../../client/get-category-initiatives';

const CategoryInitiatives: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<CategoryInitiativesResult>();

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);
      const result = await getCategoryInitiatives(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
      );

      setIsLoading(false);
      if (result.success) {
        setData(result.data);
      }
    });

    return () => dispose();
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <h2>CATEGORY INITIATIVES</h2>
        <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
        <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
        {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
        {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
        <p>Source: Heco et al</p>
      </div>
      <div className={styles.tree}>
        <ul>
          <li>
            <p>
              <span>Distribution</span>
              {
                !isLoading && data ?
                  <TreeItem
                    measureSign="%"
                    maximumFractionDigits={1}
                    currentValue={data.current.distribution}
                    lastYearPeriod={data.lastYearPeriod.distribution}
                    itemMinWidth={12}
                  />
                  : 
                  '...'
              }
            </p>
            <p>
              <span>Promotion</span>
              {
                !isLoading && data ?
                  <TreeItem
                    measureSign="%"
                    maximumFractionDigits={1}
                    currentValue={data.current.promotions}
                    lastYearPeriod={data.lastYearPeriod.promotions}
                    itemMinWidth={12}
                  />
                  : 
                  '...'
              }
            </p>
            <p>
              <span>Assortment</span>
              {
                !isLoading && data ?
                  <TreeItem
                    measureSign=" SKUs"
                    maximumFractionDigits={0}
                    currentValue={data.current.productCount}
                    lastYearPeriod={data.lastYearPeriod.productCount}
                    itemMinWidth={12}
                  />
                  : 
                  '...'
              }
            </p>
            <p>
              <span>Price</span>
              {
                !isLoading && data ?
                  <TreeItem
                    measureSign=" kr"
                    maximumFractionDigits={2}
                    currentValue={data.current.avgProductPrice}
                    lastYearPeriod={data.lastYearPeriod.avgProductPrice}
                    itemMinWidth={12}
                  />
                  : 
                  '...'
              }
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default observer(CategoryInitiatives);