import { makeObservable, observable, action } from 'mobx';
import dateAdd from 'date-fns/add';

export interface Brand {
  id: string;
  name: string;
  mainBrandName: string;
}

export interface Category {
  id: string;
  name: string;
}

export interface Merchant {
  merchant_id: string;
}

export class FiltersStore {
  isLoading: boolean = false;
  dateFrom: Date = dateAdd(new Date(), {months: -3});
  dateTo: Date = new Date();
  merchants: Merchant[] | null = null;
  intervalStart: string = '0';
  intervalLength: string = '5';
  monthEquivalent: string = '30';
  optiusCategory: Category | null = null;
  defaultCategory: Category | null = null;
  brands: Brand[] | null = null;
  availableBrands: Brand[] = [];
  availableCategories: Category[] = [];
  availableMerchants: Merchant[] = [];

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      dateFrom: observable,
      dateTo: observable,
      merchants: observable,
      intervalStart: observable,
      intervalLength: observable,
      monthEquivalent: observable,
      optiusCategory: observable,
      defaultCategory: observable,
      brands: observable,
      availableBrands: observable,
      availableCategories: observable,
      availableMerchants: observable,
      setIsLoading: action,
      setDateFrom: action,
      setDateTo: action,
      setMerchants: action,
      setIntervalStart: action,
      setIntervalLength: action,
      setMonthEquivalent: action,
      setOptiusCategory: action,
      setDefaultCategory: action,
      setBrands: action,
      setAvailableBrands: action,
      setAvailableCategories: action,
    });
  }

  setIsLoading(val: boolean) {
    this.isLoading = val;
  }

  setDateFrom(val: Date) {
    this.dateFrom = val;
  }

  setDateTo(val: Date) {
    this.dateTo = val;
  }

  setMerchants(val: Merchant[]) {
    this.merchants = val;
  }

  setIntervalStart(val: string) {
    this.intervalStart = val;
  }

  setIntervalLength(val: string) {
    this.intervalLength = val;
  }

  setMonthEquivalent(val: string) {
    this.monthEquivalent = val;
  }

  setOptiusCategory(val: Category) {
    this.optiusCategory = val;
  }

  setDefaultCategory(val: Category) {
    this.defaultCategory = val;
  }

  setBrands(val: Brand[] | null) {
    this.brands = val;
  }

  setAvailableBrands(val: Brand[]) {
    this.availableBrands = val;
  }

  setAvailableCategories(val: Category[]) {
    this.availableCategories = val;
  }

  setAvailableMerchants(val: Merchant[]) {
    this.availableMerchants = val;
  }
}

const observableFiltersStore = new FiltersStore();

export default observableFiltersStore;