import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import Logo from '../../components/logo/logo';
import styles from './forgot-password.module.css';
import Arrow from '../../components/arrow/arrow';
import Alert from '@mui/material/Alert';
import requestPasswordReset from '../../client/request-password-reset';
import observableUiStore from '../../stores/ui';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const send = async () => {
    if (!email) return setError('Email is a required field.');

    setError('');
    observableUiStore.setIsLoading(true);
    try {
      const result = await requestPasswordReset(email);
      if (result.success === true) {
        observableUiStore.setIsLoading(false);
        setIsSuccess(true);
      } else {
        observableUiStore.setIsLoading(false);
        setError('An unexpected error has occurred. Please try again.');
      }
    } catch (e) {
      observableUiStore.setIsLoading(false);
      setError(e.error || e.message || 'An unexpected error has occurred. Please try again.');
    }
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <Logo className={styles.logo} />

        <p>Please enter your work email below. We will send you an email with further instructions.</p><br/>
        
        <TextField className={styles.input} variant="outlined" value={email} type="email" label="Email" onChange={e => setEmail(e.target.value)} fullWidth />  
        <Button
          className={styles.button}
          fullWidth
          variant="contained"
          color="secondary"
          startIcon={<Arrow color={isSuccess ? 'disabled' : 'primary'} />}
          onClick={send}
          disabled={isSuccess}
        >
          Send
        </Button>
        
        <a className={styles.contact} href="https://hecoetal.com/contact">Don't have an account? Get in touch!</a>
        {error ? <Alert severity="error" className={styles.alert}>{error}</Alert> : null}
        {isSuccess ? <Alert severity="success" className={styles.alert}>Password reset email sent. Please check your mailbox.</Alert> : null}
      </div>
    </div>
  );
};

export default ForgotPassword;