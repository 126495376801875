import { LossAndGainLastTableColumns } from '../../../containers/category-where-why/category-where-why';

const merchantLossToMarket = (merchantLosing: string, merchantRows: Record<string, Record<string, number>>, matrix: Record<string, LossAndGainLastTableColumns>) => {

  const merchantLossToMarket: Record<string, number> = {};
  let sumLossToMarket = 0;

  for (const merchantName of Object.keys(merchantRows)) {

    if (merchantName === 'Increase' || merchantName === 'Total') continue;
    
    if (merchantName === merchantLosing) {
      merchantLossToMarket[merchantName] = matrix[merchantName]['Loss in %'];
      continue;
    }

    merchantLossToMarket[merchantName] = -Math.round(10000 * (merchantRows[merchantLosing][merchantName] / matrix[merchantLosing]['P1'])) / 100;
    sumLossToMarket += -merchantLossToMarket[merchantName];
  
  }

  return {
    'merchantLossToMarket': merchantLossToMarket,
    'sumLossToMarket': Math.round(100 * sumLossToMarket) / 100,
  };
};

export default merchantLossToMarket;
