import React from 'react';
import styles from './promotion-weeks-initiatives.module.css';
import { autorun } from 'mobx';
import observableFiltersStore from '../../stores/filters';
import dateFormat from 'date-fns/format';
import getPromotionWeeksInitiatives from '../../client/get-promotion-weeks-initiatives';
import { ResponsiveBar } from '@nivo/bar';

const PromotionWeeksInitiatives: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>();

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const result = await getPromotionWeeksInitiatives(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
      );

      if (result.success) {
        setData(result.data);
      }

      setIsLoading(false);
    });

    return () => dispose();
  }, []);

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <h2>PROMOTION INITIATIVES</h2>
          <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
          <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
          {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
          {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
          <p>Source: Heco et al</p>
          <div className={styles.legend}>
            <div className={styles.item}>
              <div className={styles.square} style={{background: '#0096FF'}}></div>
              <div className={styles.item}>Average price</div>
            </div>
            <div className={styles.item}>
              <div className={styles.square} style={{background: '#21B8FE'}}></div>
              <div className={styles.item}>Promotion %</div>
            </div>
            <div className={styles.item}>
              <div className={styles.square} style={{background: '#A3E2FF'}}></div>
              <div className={styles.item}>Distribution %</div>
            </div>
          </div>
        </div>
        <div className={styles.graph}>
          {isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
          {!isLoading && data && 
            <ResponsiveBar
              data={data.reduce((acc: any[], curr: any) => acc = [...acc, { Week: curr.week_number === 'Average' ? curr.week_number : 'Week ' + curr.week_number, 'Average price': Math.round(100 * curr.average_price) / 100, 'Promotion %': Math.round(curr.promotion) , 'Distribution %': Math.round(curr.distribution) }], [])}
              indexBy="Week"
              keys={[
                'Average price',
                'Promotion %',
                'Distribution %',
              ]}
              groupMode="grouped"
              layout="vertical"
              padding={0.3}
              margin={{ top: 20, right: 30, bottom: 30, left: 30 }}
              colors={['#0096FF', '#21B8FE', '#A3E2FF']}
              indexScale={{ type: 'band', round: true }}
              enableGridX={false}
              enableGridY={true}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendOffset: 32,
                legend: '',
                legendPosition: 'middle',
              }}
              axisLeft={{
                tickSize: 2,
                tickPadding: 5,
                tickRotation: 0,
              }}
              labelSkipWidth={12}
              labelSkipHeight={0}
              labelTextColor="black"
              theme={{
                axis: {
                  ticks: {
                    text: {
                      fontSize: data.length > 20 ? 7 : data.length > 15 ? 9 : 11,
                      fontWeight: 'bold',
                    },
                  },
                },
              }}
              ariaLabel="Promotion Initiatives Grouped Bar Chart"
            />
          }
        </div>
      </div>
    </>
  );
};

export default PromotionWeeksInitiatives;
