import React from 'react';
import styles from './promotion-loss-and-gain.module.css';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import dateFormat from 'date-fns/format';
import observableFiltersStore from '../../stores/filters';
import getPromotionLossAndGain from '../../client/get-promotion-loss-and-gain';
import { ResponsiveBar } from '@nivo/bar';

interface PromotionLossAndGainProps {
  week: string;
  setWeek: (week: string) => void;
  type: string;
  setType: (type: string) => void;
}

const PromotionLossAndGain: React.FC<PromotionLossAndGainProps> = props => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any[]>();

  React.useEffect(() => {
    const dispose = autorun(async () => {
      setIsLoading(true);

      const resultPromotionLossAndGain = await getPromotionLossAndGain(
        observableFiltersStore.dateFrom,
        observableFiltersStore.dateTo,
        observableFiltersStore.optiusCategory?.id,
        observableFiltersStore.merchants?.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id], []),
        observableFiltersStore.brands?.reduce((acc: string[], curr) => acc = [...acc, curr.id], []),
      );

      if (resultPromotionLossAndGain.success) {
        const averageWeekIndexInData = resultPromotionLossAndGain.data.findIndex(dataObj => dataObj.Week === 'Average');
        if (averageWeekIndexInData !== -1) {
          resultPromotionLossAndGain.data.splice(averageWeekIndexInData, 1);
        }
        setData(resultPromotionLossAndGain.data);
      }

      setIsLoading(false);
    });

    return () => dispose();
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <div className={styles.info}>
          <h2>GAIN {`&`} LOSS PROMO</h2>
          <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
          <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
          {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
          {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
          <p>Source: Heco et al</p>
        </div>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#FE872B'}}></div>
            <div className={styles.item}>Lost buyers in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#A3E2FF'}}></div>
            <div className={styles.item}>New buyers in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#FF9D2C'}}></div>
            <div className={styles.item}>Decreased spending in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#21B8FE'}}></div>
            <div className={styles.item}>Increased spending in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#FFC62F'}}></div>
            <div className={styles.item}>Loss to market in %</div>
          </div>
          <div className={styles.item}>
            <div className={styles.square} style={{background: '#0096FF'}}></div>
            <div className={styles.item}>Gain from market in %</div>
          </div>
        </div>
      </div>
      <div style={{height: '34rem'}}>
        {isLoading && <h3 className={styles.isLoading}>Loading...</h3>}
        {!isLoading && data && 
          <ResponsiveBar
            data={data}
            indexBy="Week"
            keys={[
              'Gain from market in %', 
              'Increased spending in %', 
              'New buyers in %', 
              'Lost buyers in %', 
              'Decreased spending in %', 
              'Loss to market in %', 
            ].reverse()}
            groupMode="stacked"
            layout="horizontal"
            padding={0.4}
            margin={{ top: 0, right: 20, bottom: 0, left: 60 }}
            colors={['#0096FF', '#21B8FE', '#A3E2FF', '#FE872B', '#FF9D2C', '#FFC62F'].reverse()}
            indexScale={{ type: 'band', round: true }}
            enableGridX={false}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            axisLeft={{
              tickSize: 2,
              tickPadding: 5,
              tickRotation: 0,
            }}
            labelSkipWidth={12}
            labelSkipHeight={0}
            labelTextColor="black"
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: data.length > 20 ? 7 : data.length > 15 ? 9 : 11,
                    fontWeight: 'bold',
                  },
                },
              },
            }}
            ariaLabel="Promotion Loss and Gain Stacked Bar Chart"
            onClick={(data) => {
              props.setWeek(data['indexValue'].toString().split(' ')[1]);

              const typeClicked = data.id.toString();
              if (typeClicked === 'Increased spending in %') {
                props.setType('Increased spending');
              } else if (typeClicked === 'Gain from market in %') {
                props.setType('Gain from market');
              } else if (typeClicked === 'Loss to market in %') {
                props.setType('Loss to market');
              } else if (typeClicked === 'Decreased spending in %') {
                props.setType('Decreased spending');
              } else if (typeClicked === 'Lost buyers in %') {
                props.setType('Lost buyers');
              } else if (typeClicked === 'New buyers in %') {
                props.setType('New buyers');
              }
            }}
          />
        }
      </div>
      {props.week && props.type && 
        <div className={styles.clearChoiceButton}>
          <div>Week: {props.week}</div>
          <div>Type: {props.type}</div>
          <button onClick={() => {
            props.setWeek('');
            props.setType('');
          }}>
            Clear choice
          </button>
        </div>
      }
    </div>
  );
};

export default observer(PromotionLossAndGain);
