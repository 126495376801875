import { MerchantDuplication } from '../../../containers/category-where-why/category-where-why';

const merchantOnlyAt = (merchant: string, dataDuplication: MerchantDuplication[]) => {
  
  const index = dataDuplication.findIndex(merchantObject => merchantObject['Merchant'] === merchant);

  return [{
    'Merchant': merchant,
    'Only at': dataDuplication[index]['Only at'],
  }];
};

export default merchantOnlyAt;
