import React from 'react';
import styles from './transition.module.css';
import { LossAndGainLastTableColumns, MerchantDuplicationAndTransition } from '../../containers/category-where-why/category-where-why';

import merchantGainFromMarket from './extract-data/merchantGainFromMarket';
import merchantLossToMarket from './extract-data/merchantLossToMarket';
import merchantGainedToCategory from './extract-data/merchantGainedToCategory';
import merchantLostFromCategory from './extract-data/merchantLostFromCategory';

import formatDataForMerchantGainFromMarket from './format-data/formatDataForMerchantGainFromMarket';
import formatDataForMerchantLossToMarket from './format-data/formatDataForMerchantLossToMarket';

import GainFromMarketChart from './watterfall-charts/GainFromMarketChart';
import LossToMarketChart from './watterfall-charts/LossToMarketChart';
import GainedToCategoryChart from './watterfall-charts/GainedToCategoryChart';
import LostFromCategoryChart from './watterfall-charts/LostFromCategoryChart';

import merchantSharing from './extract-data/merchantSharing';
import merchantElsewhere from './extract-data/merchantElsewhere';
import merchantOnlyAt from './extract-data/merchantOnlyAt';
import merchantNotInTheShop from './extract-data/merchantNotInTheShop';

import formatDataForMerchantSharing from './format-data/formatDataForMerchantSharing';
import formatDataForMerchantElsewhere from './format-data/formatDataForMerchantElsewhere';

import OnlyAtChart from './watterfall-charts/OnlyAtChart';
import NotInTheShopChart from './watterfall-charts/NotInTheShopChart';
import SharingChart from './watterfall-charts/SharingChart';
import ElsewhereChart from './watterfall-charts/ElsewhereChart';
import dateFormat from 'date-fns/format';
import observableFiltersStore from '../../stores/filters';

interface TransitionProps {
  merchant?: string,
  typeOfChart?: string,
  rawData: Record<string, Record<string, number>>,
  matrix: Record<string, LossAndGainLastTableColumns>,
  dataDuplicationAndTransition: MerchantDuplicationAndTransition,
  setMerchant2: (merchant: string) => void,
}

const Transition: React.FC<TransitionProps> = (props: TransitionProps) => {
  return (
    <div className={styles.middlesChild}>
      {!props.typeOfChart && (
        <div className={styles.transitionInfo}>
          <h2>TRANSITIION</h2>
          <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
          <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
          {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
          {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
          <p className={styles.transitionInfo}>Source: Heco et al</p>
          <p> 
            Deep dive the transition i.e. where do shoppers buy by choosing a retail chain and a metric in the Loss {'&'} Gain or Duplication graph
          </p>
        </div>
      )}
      {props.typeOfChart &&
        <div>
          <p><span className={styles.h1Header}>TRANSITIION</span>, {props.merchant + ' ' + props.typeOfChart}</p>
          <p>Period: {dateFormat(observableFiltersStore.dateFrom, 'd.M.yyyy')} - {dateFormat(observableFiltersStore.dateTo, 'd.M.yyyy')}</p>
          <p>Category: {observableFiltersStore.optiusCategory?.name || observableFiltersStore.defaultCategory?.name}</p>
          {observableFiltersStore.merchants && observableFiltersStore.merchants.length > 0 ? <p>Markets: {observableFiltersStore.merchants.reduce((acc: string[], curr) => acc = [...acc, curr.merchant_id.toUpperCase()], []).join(', ')}</p> : null}
          {observableFiltersStore.brands && observableFiltersStore.brands.length > 0 ? <p>Brands: {observableFiltersStore.brands.reduce((acc: string[], curr) => acc = [...acc, curr.name.toUpperCase()], []).join(', ')}</p> : null}
          <p>Source: Heco et al</p>
        </div>
      }
      {props.typeOfChart === 'Gain from market' && props.merchant && (
        <div style={{'height': '11rem'}}>
          <GainFromMarketChart merchant={props.merchant} setMerchant2={props.setMerchant2} data={formatDataForMerchantGainFromMarket(props.merchant, merchantGainFromMarket(props.merchant, props.rawData, props.matrix))} />
        </div>
      )}
      {props.typeOfChart === 'Loss to market' && props.merchant && (
        <div style={{'height': '11rem'}}>
          <LossToMarketChart merchant={props.merchant} setMerchant2={props.setMerchant2} data={formatDataForMerchantLossToMarket(props.merchant, merchantLossToMarket(props.merchant, props.rawData, props.matrix))} />
        </div>
      )}
      {props.typeOfChart === 'Gained to category' && props.merchant && (
        <div style={{'height': '11rem'}}>
          <GainedToCategoryChart data={merchantGainedToCategory(props.merchant, props.matrix)} />
        </div>
      )}
      {props.typeOfChart === 'Lost from category' && props.merchant && (
        <div style={{'height': '11rem'}}>
          <LostFromCategoryChart data={merchantLostFromCategory(props.merchant, props.matrix)} />
        </div>
      )}
      {props.typeOfChart === 'Sharing' && props.merchant && (
        <div style={{'height': '11rem'}}>
          <SharingChart merchant={props.merchant} setMerchant2={props.setMerchant2} data={formatDataForMerchantSharing(props.merchant, merchantSharing(props.merchant, props.dataDuplicationAndTransition.merchantsSharing))} />
        </div>
      )}      
      {props.typeOfChart === 'Elsewhere' && props.merchant && (
        <div style={{'height': '11rem'}}>
          <ElsewhereChart merchant={props.merchant} setMerchant2={props.setMerchant2} data={formatDataForMerchantElsewhere(props.merchant, merchantElsewhere(props.merchant, props.dataDuplicationAndTransition.merchantsElsewhere))} />
        </div>
      )}
      {props.typeOfChart === 'Only at' && props.merchant && (
        <div style={{'height': '11rem'}}>
          <OnlyAtChart data={merchantOnlyAt(props.merchant, props.dataDuplicationAndTransition.dataDuplication)} />
        </div>
      )}
      {props.typeOfChart === 'Not in the shop' && props.merchant && (
        <div style={{'height': '11rem'}}>
          <NotInTheShopChart data={merchantNotInTheShop(props.merchant, props.dataDuplicationAndTransition.dataDuplication)} />
        </div>
      )}
    </div>
  );
};

export default Transition;
