import { getAuth } from 'firebase/auth';
import {Result} from './models';
import dateFormat from 'date-fns/format';

interface GeographyModel {
  code: string;
  count: number;
}

interface AgeModel {
  period: string;
  count: number;
}

interface ConsumptionPartModel {
  one_per_week: number;
  one_per_2weeks: number;
  one_per_30days: number;
  one_per_90days: number;
  one_per_180days: number;
  one_per_360days: number;
}

interface ConsumptionModel {
  categorization: ConsumptionPartModel;
  values: ConsumptionPartModel;
}

interface FamilyModel {
  id: string;
  count: number;
}

interface BasketModel {
  name: string;
  average_value: number;
}

export interface CategoryUserDataModel {
  geography: GeographyModel[];
  age: AgeModel[];
  consumption: ConsumptionModel;
  familyType: FamilyModel[];
  basketSize: BasketModel[];
  relevantUserIds: string[];
}

const getCategoryUserData = async (dateFrom: Date, dateTo: Date, categoryId?: string, brandIds?: string[], functionality?: string): Promise<Result<CategoryUserDataModel>> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();

  if (!token) {
    throw new Error('Unauthorized');
  }

  const response = await fetch(`${process.env.REACT_APP_API}/v1/get-category-user-data?date_from=${dateFormat(dateFrom, 'yyyy-MM-dd')}&date_to=${dateFormat(dateTo, 'yyyy-MM-dd')}&category_id=${categoryId || ''}&brand_ids=${brandIds?.reduce((acc: string[], curr) => acc = [...acc, encodeURIComponent(curr)], [])?.join(',') || ''}&functionality=${functionality}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
  return await response.json();
};

export default getCategoryUserData;
